import React from 'react';
import { Helmet } from 'react-helmet';
import { Environment } from 'utils/Authentication/EnvironmentVariables';
import CurrentProfileManager from '@sekoia/shared/utils/currentProfileManager';

let walkMeUrl: string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const w = window as any;

export default function WalkMe() {
  const cachedProfile = CurrentProfileManager.Instance.getCurrentProfile();
  const environment = process.env.SEKOIA_ENV;
  walkMeUrl =
    'https://cdn.walkme.com/users/742733e973ce408eb4886b9a52ccc6a3/test/walkme_742733e973ce408eb4886b9a52ccc6a3_https.js';
  if (environment === Environment.prod.toString()) {
    walkMeUrl =
      'https://cdn.walkme.com/users/742733e973ce408eb4886b9a52ccc6a3/walkme_742733e973ce408eb4886b9a52ccc6a3_https.js';
  }
  w.walkme_get_language = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const language = (window.navigator as any).userLanguage || window.navigator.language;
    return language;
  };
  w._walkmeConfig = { smartLoad: true };
  if (cachedProfile === undefined) return null;
  w.CurrentUserFullName = cachedProfile.fullName;
  w.CurrentUserId = cachedProfile.id;

  return (
    <Helmet>
      <script async src={walkMeUrl} type="text/javascript" />
    </Helmet>
  );
}
