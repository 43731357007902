async function readLocalFile() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise((resolve, reject) => {
    const uploadInput = document.createElement('input');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploadInput.addEventListener('change', (_) => {
      const file = uploadInput.files[0];
      resolve({
        type: file.type,
        name: file.name,
        file,
      });
    });

    // This input element in IE11 becomes visible after it is added on the page
    // Hide an input element
    uploadInput.style.visibility = 'hidden';

    uploadInput.type = 'file';
    document.body.appendChild(uploadInput);
    uploadInput.click();
    document.body.removeChild(uploadInput);
  });
}

export { readLocalFile };
