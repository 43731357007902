import React, { useMemo } from 'react';
import useFetchEmployees, { EmployeeResponse } from '@sekoia/shared/requests/atlas/useFetchEmployees';
import { Skeleton } from '@mui/material';
import ProfileSelector, { IdAndName } from './ProfileSelector';

type EmployeeSelectorProps = {
  label: string;
  noOptionsText: string;
  placeholder?: string;
  handleOnEmployeeChange?: (employee: EmployeeResponse | undefined) => void;
  handleOnEmployeesChange?: (employees: EmployeeResponse[]) => void;
  employeesToList?: EmployeeResponse[];
  value?: string | string[] | null;
  multiple?: boolean;
  excludeEmployeeIds?: string[];
  organisationsToInclude?: string[];
  required?: boolean;
};

const EmployeeSelector: React.FC<React.PropsWithChildren<EmployeeSelectorProps>> = (props) => {
  const {
    label,
    noOptionsText,
    placeholder,
    handleOnEmployeeChange,
    handleOnEmployeesChange,
    employeesToList,
    value,
    multiple = false,
    excludeEmployeeIds,
    organisationsToInclude,
    required = false,
  } = props;
  const { data: employees, fetchStatus: employeesFetchStatus } = useFetchEmployees(
    [],
    false,
    employeesToList === undefined,
  );

  const profiles = useMemo(() => {
    const idAndNames: IdAndName[] = [];
    if (employeesToList) {
      employeesToList?.forEach((e) => {
        if (organisationsToInclude) {
          if (e.associatedOrganisationIds.some((org) => organisationsToInclude.includes(org))) {
            idAndNames.push({
              name: `${e.firstname} ${e.lastname} `,
              id: e.globalId,
              customerId: e.customerId,
            });
          }
        } else {
          idAndNames.push({
            name: `${e.firstname} ${e.lastname} `,
            id: e.globalId,
            customerId: e.customerId,
          });
        }
      });
    } else {
      employees?.forEach((e) => {
        if (organisationsToInclude) {
          if (e.associatedOrganisationIds.some((org) => organisationsToInclude.includes(org))) {
            idAndNames.push({
              name: `${e.firstname} ${e.lastname} `,
              id: e.globalId,
              customerId: e.customerId,
            });
          }
        } else {
          idAndNames.push({
            name: `${e.firstname} ${e.lastname} `,
            id: e.globalId,
            customerId: e.customerId,
          });
        }
      });
    }
    excludeEmployeeIds?.forEach((employeeId) => {
      const index = idAndNames.findIndex((e) => e.id === employeeId);
      index > -1 && idAndNames.splice(index, 1);
    });
    return idAndNames;
  }, [employees, employeesToList, excludeEmployeeIds, organisationsToInclude]);

  const handleOnProfileChange = (profile: IdAndName | undefined) => {
    if (!profile) return;
    const employeesObject = employeesToList ?? employees;
    const employee = employeesObject?.find((e) => e && e.globalId === profile.id);
    handleOnEmployeeChange && handleOnEmployeeChange(employee);
  };
  const handleOnProfilesChange = (profiles: IdAndName[]) => {
    const employeesObject = employeesToList ?? employees;
    const employeesSelected = employeesObject?.filter((e) => profiles.map((i) => i.id).includes(e.globalId));
    handleOnEmployeesChange && handleOnEmployeesChange(employeesSelected ?? []);
  };

  if (employeesFetchStatus === 'fetching') {
    return <Skeleton width={'100%'} variant="rectangular" height={56} />;
  }

  return (
    <ProfileSelector
      label={label}
      noOptionsText={noOptionsText}
      profiles={profiles}
      handleOnProfileChange={handleOnProfileChange}
      handleOnProfilesChange={handleOnProfilesChange}
      multiple={multiple}
      placeholder={placeholder}
      required={required}
      value={value}
    />
  );
};

export default EmployeeSelector;
EmployeeSelector.displayName = 'EmployeeSelector';
