import { useContext } from 'react';
import { RestClientsContext } from '../../contexts/RestClients/RestClientsContext';
import { ai } from '@sekoia/shared/utils/telemetryService';

export const useDashboardsClient = () => {
  const { dashboards } = useContext(RestClientsContext);

  if (!dashboards) {
    ai.trackException(
      `No Dashboards client found, did you forget to wrap the application with a <RestClientsProvider />?`,
    );
    throw new Error(
      `No Dashboards client found, did you forget to wrap the application with '<RestClientsProvider />?`,
    );
  }

  return dashboards;
};
