import { useContext } from 'react';
import { RestClientsContext } from '../../contexts/RestClients/RestClientsContext';
import { ai } from '@sekoia/shared/utils/telemetryService';

export const useNexusClient = () => {
  const { nexus } = useContext(RestClientsContext);

  if (!nexus) {
    ai.trackException(`No Nexus client found, did you forget to wrap the application with a <RestClientsProvider />?`);
    throw new Error(`No Nexus client found, did you forget to wrap the application with '<RestClientsProvider />?`);
  }

  return nexus;
};
