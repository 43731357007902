import { User, UserManager, UserManagerSettings } from 'oidc-client';
import IEnvironmentInformation from './IEnvironmentInformation';
import JWTTokenDecoder from './jwtTokenDecoder';
import CurrentProfileManager from '@sekoia/shared/utils/currentProfileManager';
import { ai } from '@sekoia/shared/utils/telemetryService';
import * as fetchIntercept from 'fetch-intercept';
import { FetchInterceptorResponse } from 'fetch-intercept';
import { ProfileResponse } from '@sekoia/shared/requests/nswagClients/AtlasClient';
import { updateClarityIdentity } from '@sekoia/shared/helpers/clarity';

const sekoiaHostnameRegexp = /\.sekoia\.one$|\.sekoia\.dk$|\.sekoia.\se$|\.sekoia\.co\.uk$/g;
type TokenProfileInfo = {
  globalId: string;
  legacyId: string;
  roles: string[] | string;
  customerId: string;
  compassUrl: string;
  cultureInfoCode: string;
  accessControlTags: string[];
  professionLogoTypeId: string;
};
export class Authentication {
  private _environmentInformation: IEnvironmentInformation;
  public userManager: UserManager;
  private _jwtTokenDecoder: JWTTokenDecoder;
  private userRoles: string[] = [];
  public static Instance = new Authentication();

  constructor() {
    if (!process.env.LOGIN_SERVICE_URL || !process.env.AUTH_CLIENT_ID) {
      throw new Error();
    }
    let authenticationUrl = process.env.LOGIN_SERVICE_URL;
    //TEMP CODE TO TEST NEW AUTH
    const newAuth = localStorage.getItem('newauth');
    if (newAuth && newAuth === 'true' && process.env.LOGIN_SERVICE_URL_V2) {
      authenticationUrl = process.env.LOGIN_SERVICE_URL_V2;
    }
    this._environmentInformation = {
      BaseAuthUrl: authenticationUrl,
      ClientId: process.env.AUTH_CLIENT_ID,
      Scope: 'urn:sekoia:admin',
    };
    this._jwtTokenDecoder = new JWTTokenDecoder();
    this.userManager = new UserManager(this.getClientSettings());

    this.userManager.events.addUserLoaded(async () => {
      await this.setUserInfo();
    });

    // Intercept fetch 401
    fetchIntercept.register({
      response(response: FetchInterceptorResponse): FetchInterceptorResponse {
        const responseUrl = new URL(response.url);
        if (response.status === 401 && responseUrl.hostname.match(sekoiaHostnameRegexp)) {
          Authentication.Instance.login();
        }

        return response;
      },
    });
  }

  public async setUserInfo(): Promise<void> {
    const token = await this.getToken();

    if (!token || token === '') return;

    const {
      nameid: globalId,
      unique_name: legacyId,
      role: roles,
      'sekoia:CustomerId': customerId,
      compassUrl,
      cultureInfoCode,
    } = this._jwtTokenDecoder.decodeToken(token);

    localStorage.setItem('customerId', customerId);

    CurrentProfileManager.Instance.setIdAndLegacyId(globalId, parseInt(legacyId));
    CurrentProfileManager.Instance.setCustomerInfo({
      compassUrl,
      customerId,
      cultureInfoCode,
    });
    ai.setAuthenticatedUser(globalId);
    updateClarityIdentity(globalId);

    if (Array.isArray(roles)) this.userRoles = roles.filter((role: string) => !parseInt(role)) as string[];
    else this.userRoles = [roles as string];
  }

  public async getProfileInformationFromToken(): Promise<TokenProfileInfo> {
    const token = await this.getToken();

    const {
      nameid: globalId,
      unique_name: legacyId,
      role: roles,
      'sekoia:CustomerId': customerId,
      compassUrl,
      cultureInfoCode,
      'sekoia:AccessControlTags': accessControlTagsRaw,
      'sekoia:ProfessionLogoId': professionLogoTypeId,
    } = this._jwtTokenDecoder.decodeToken(token);

    const accessControlTags = Array.isArray(accessControlTagsRaw)
      ? accessControlTagsRaw
      : accessControlTagsRaw
      ? [accessControlTagsRaw]
      : [];

    return {
      accessControlTags,
      globalId,
      legacyId,
      roles,
      customerId,
      compassUrl,
      cultureInfoCode,
      professionLogoTypeId,
    };
  }

  public async authenticate(): Promise<User | null> {
    const url = window.location.href;
    try {
      await this.userManager.signinRedirectCallback(url);
      const profile = await this.userManager.getUser();

      if (!profile) {
        await this.logout();
      }

      return profile;
    } catch (e) {
      ai.trackException({
        exception: e as Error,
        properties: { message: 'Error logging in, restarting flow' },
      });
      await this.userManager.clearStaleState();
      window.sessionStorage.clear();
      window.location.replace(`${window.location.protocol}//${window.location.host}`);
    }

    return null;
  }

  public login(): void {
    this.userManager.signinRedirect();
  }

  public async logout(): Promise<void> {
    const user = await this.userManager.getUser();

    Authentication.Instance.userManager.stopSilentRenew();

    localStorage.removeItem('customerId');
    window.sessionStorage.clear();

    this.userManager.clearStaleState();

    try {
      await this.userManager.removeUser();
    } catch (error) {
      ai.trackException({
        exception: error as Error,
        properties: { message: 'Error removing user from OIDC managers' },
      });
    }

    let args = {};
    if (user?.id_token) args = { id_token_hint: user?.id_token };
    await Authentication.Instance.userManager.signoutRedirect(args);
  }

  public async getToken(): Promise<string> {
    const user = await this.userManager.getUser();
    if (!user) return '';
    return user.access_token;
  }

  public async setCurrentProfile(profile: ProfileResponse) {
    await CurrentProfileManager.Instance.setCurrentProfile(profile, this.userRoles);
  }

  private getClientSettings(): UserManagerSettings {
    return {
      authority: `${this._environmentInformation.BaseAuthUrl}/.well-known/openid-configuration`,
      accessTokenExpiringNotificationTime: 180,
      automaticSilentRenew: true,
      client_id: 'administration',
      post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}/`,
      redirect_uri: `${window.location.protocol}//${window.location.host}/login.html`,
      response_type: 'code',
      scope: 'api offline_access IdentityServerApi openid userprofile',
      silent_redirect_uri: `${window.location.protocol}//${window.location.host}/login.html`,
      clockSkew: 300,
    };
  }
}
