import { Box, Grid } from '@mui/material';
import { Page } from 'components/Page';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseErrorView from 'views/base/BaseErrorView';

const ExternalLayout: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { t } = useTranslation(['global']);
  return (
    <Page title="Sekoia" style={{ backgroundColor: 'white' }}>
      <Grid container spacing={2}>
        <Grid item container xs={12} justifyContent={'center'}>
          <Box m={2}>
            <img src="/static/sekoia-logo.png" title="Sekoia" alt="Sekoia" />
          </Box>
        </Grid>
        <Grid item container xs={12} justifyContent={'center'}>
          <Box>
            <BaseErrorView
              title={t('menuContentErrorTitle')}
              description={t('menuContentErrorDescription')}
              buttonText={t('tryAgain')}
            >
              {props.children}
            </BaseErrorView>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export default ExternalLayout;
