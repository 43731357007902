import { NotificationsContextClient } from '@sekoia/shared/contexts/RestClients/Clients/NotificationsContextClient';
import React, { useMemo } from 'react';
import { NexusContextClient } from './Clients/NexusContextClient';
import { ObservationsContextClient } from './Clients/ObservationsContextClient';
import { TasksContextClient } from './Clients/TasksContextClient';
import { AtlasContextClient } from './Clients/AtlasContextClient';
import { RestClientsContext } from './RestClientsContext';
import { HealthMeasurementsContextClient } from './Clients/HealthMeasurementsContextClient';
import { RecordsContextClient } from './Clients/RecordsContextClient';
import { PlansContextClient } from './Clients/PlansContextClient';
import { DashboardsContextClient } from './Clients/DashboardsContextClient';
import { ChinabookContextClient } from './Clients/ChinabookContextClient';
import { ReportsContextClient } from './Clients/ReportsContextClient';
import { TogglesContextClient } from './Clients/TogglesContextClient';

export const RestClientsProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const providerValue = useMemo(() => {
    return {
      atlas: new AtlasContextClient(),
      nexus: new NexusContextClient(),
      tasks: new TasksContextClient(),
      observations: new ObservationsContextClient(),
      healthMeasurements: new HealthMeasurementsContextClient(),
      notifications: new NotificationsContextClient(),
      records: new RecordsContextClient(),
      plans: new PlansContextClient(),
      dashboards: new DashboardsContextClient(),
      chinabook: new ChinabookContextClient(),
      reports: new ReportsContextClient(),
      toggles: new TogglesContextClient(),
    };
  }, []);

  return <RestClientsContext.Provider value={providerValue}>{props.children}</RestClientsContext.Provider>;
};
