import { roles } from '@sekoia/shared/domain/User/roles';
import { useFetchEmployeeById } from '@sekoia/shared/requests/atlas/useFetchEmployeeById';
import LoadingScreen from 'components/LoadingScreen';
import { useTokenContext } from 'context/Token';
import { Profile } from 'context/Token/types';
import { useFetchUserInfo } from 'hooks/requests/profile/useFetchProfileInformation';
import useFetchResidents from 'hooks/requests/useFetchResidents';
import React, { useContext, useMemo } from 'react';

interface ProfileContext extends Profile {
  organizationIds: string[];
  department?: string;
  title?: string;
  fullName: string;
  cultureInfoCode: string;
}

const ProfileContext = React.createContext<ProfileContext | null>(null);

export const useProfileContext = () => {
  const profileContext = useContext(ProfileContext);

  if (!profileContext) {
    throw Error(`No profile context found!`);
  }

  return profileContext;
};

export const ProfileProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { children } = props;
  const {
    accessControlCheck,
    accessControlTags,
    roles: userRoles,
    customerId,
    professionLogoTypeId,
    customerUrl,
    globalId,
    cultureInfoCode,
  } = useTokenContext();
  const employeeProfile = userRoles.some((r) => r === roles.employeeUser.name);
  const relative = userRoles.some((r) => r === roles.relative.name);
  const { data: userInfo, status: userStatus, fetchStatus: userFetchStatus } = useFetchUserInfo();
  const {
    data: employee,
    status,
    fetchStatus: employeeFetchStatus,
  } = useFetchEmployeeById(employeeProfile ? globalId : undefined);
  const { data: residents, status: residentsStatus } = useFetchResidents(relative || employeeProfile);

  const currentProfile = useMemo(() => {
    if (!userInfo) return undefined;
    const p: ProfileContext = {
      ...userInfo,
      legacyId: Number(userInfo.legacyId),
      roles: userRoles,
      organizationIds: [],
      fullName: `${userInfo.firstName} ${userInfo.lastName}`,
      cultureInfoCode: cultureInfoCode,
      accessControlCheck,
      accessControlTags,
      customerId,
      customerUrl,
      professionLogoTypeId,
    };

    if (employee) {
      p.organizationIds = employee.associatedOrganisationIds;
      if (employee.department) p.department = employee.department;
      if (employee.title) p.title = employee.title;
    } else if (residents && relative) {
      const residentsOrganizationIds = Array.from(new Set(residents.map((r) => r.organisationId)));
      p.organizationIds = residentsOrganizationIds;
    }

    return p;
  }, [
    accessControlCheck,
    accessControlTags,
    cultureInfoCode,
    customerId,
    customerUrl,
    employee,
    professionLogoTypeId,
    relative,
    residents,
    userInfo,
    userRoles,
  ]);

  if (employeeFetchStatus === 'fetching' || residentsStatus === 'pending' || userFetchStatus === 'fetching')
    return <LoadingScreen />;
  if (status === 'error' || residentsStatus === 'error' || userStatus === 'error' || !currentProfile)
    throw new Error('profile fetch failed');
  return <ProfileContext.Provider value={{ ...currentProfile }}>{children}</ProfileContext.Provider>;
};
