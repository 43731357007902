import { Grid, Box, Typography, Button, TextField, Link } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ParamsKey } from 'routes/Params';
import { useParams } from 'react-router';
import { useInvitationStyles } from 'views/external/invitations/styles';
import useSetContactPassword, {
  UseSetContactPasswordMutationRequest,
} from 'hooks/requests/contacts/useSetContactPassword';

type ResetPasswordProps = {
  username: string;
};

const ResetPassword = ({ username }: ResetPasswordProps) => {
  const { t } = useTranslation(['global', 'external']);
  const classes = useInvitationStyles();
  const { key } = useParams<ParamsKey>();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSame, setIsSame] = useState(true);
  const { mutateAsync: setContactPassword, status } = useSetContactPassword();

  const handleResetClick = useCallback(() => {
    setContactPassword({ key: key, password: password } as UseSetContactPasswordMutationRequest);
  }, [key, password, setContactPassword]);

  useEffect(() => {
    setIsSame(confirmPassword.length === 0 || password === confirmPassword);
  }, [confirmPassword, password]);

  const handleSetPassword = useCallback((element: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(element.target.value);
  }, []);

  const handleSetConfirmPassword = useCallback((element: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(element.target.value);
  }, []);

  if (status === 'success')
    return (
      <Grid container className={classes.root} spacing={2} style={{ maxWidth: 500, textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography variant={'h4'}>{t('external:resetPassword.success.title')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button component={Link} href={process.env.ADMIN_URL} color={'primary'}>
            {t('external:resetPassword.success.loginlink')}
          </Button>
        </Grid>
      </Grid>
    );

  if (status === 'error')
    return (
      <Grid container className={classes.root} spacing={2} style={{ maxWidth: 500, textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography variant={'h4'}>{t('external:resetPassword.error.title')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body1'}>{t('external:resetPassword.error.description')}</Typography>
        </Grid>
      </Grid>
    );

  return (
    <Grid container className={classes.root} spacing={2} style={{ maxWidth: 500 }}>
      <Grid item xs={12}>
        <Typography variant={'h4'}>{t('external:resetPassword.title')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body1'}>{t('external:resetPassword.description')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body1'}>{`${t('global:profileInformation.username.label')}: ${username}`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={t('global:profileInformation.password.label')}
          variant="outlined"
          type={'password'}
          fullWidth
          onChange={handleSetPassword}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={t('global:profileInformation.confirmPassword.label')}
          variant="outlined"
          type={'password'}
          fullWidth
          onChange={handleSetConfirmPassword}
          error={!isSame}
          helperText={
            !isSame && confirmPassword.length > 0 && t('global:profileInformation.confirmPassword.matchError')
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Box textAlign={'right'}>
          <Button
            variant={'contained'}
            color={'primary'}
            disabled={!isSame || confirmPassword.length < 8}
            onClick={handleResetClick}
          >
            {t('global:reset')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
