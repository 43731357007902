import notifUtils from './notifications';
import i18n from 'i18next';

export default function onFailErrors({
  appInsights,
  getNotifications,
  label,
  notificationId,
  updateNotifications,
  message,
  statusCode = null,
}) {
  const notifications = getNotifications();
  let newNotifications = notifUtils.removeNotification(notifications, notificationId);

  let labelError = `${label} ${i18n.t('global:error')}`;
  if (notificationId === 'upload' && statusCode === 400) labelError = i18n.t('files:uploadErrorGenericMessage');

  const newNotification = {
    title: message || `${labelError}`,
    minimizable: false,
    closable: true,
    children: [],
    onHide: () => updateNotifications(notifUtils.removeNotification(notifications, notificationId)),
  };

  appInsights.trackException({
    exception: new Error(`Failed in Files, ${message || label} error`),
  });

  const notification = notifUtils.getNotification(notifications, notificationId);

  newNotifications = notification
    ? notifUtils.updateNotification(notifications, notificationId, newNotification)
    : notifUtils.addNotification(notifications, notificationId, newNotification);

  updateNotifications(newNotifications);
}
