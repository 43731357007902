import api from '../api';
import onFailError from '../utils/onFailError';

// eslint-disable-next-line import/no-anonymous-default-export
export default (apiOptions, actions) => {
  const { updateNotifications, getResource, getNotifications } = actions;
  return {
    id: 'sort',
    shouldBeAvailable: () => true,
    handler: async ({ sortBy, sortDirection }) => {
      const id = getResource().id;
      try {
        return api.getChildrenForId(apiOptions, { id, sortBy, sortDirection });
      } catch (err) {
        onFailError({
          appInsights: apiOptions.appInsights,
          getNotifications,
          notificationId: 'rename',
          updateNotifications,
        });
        return null;
      }
    },
  };
};
