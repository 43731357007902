import { useMutation } from '@tanstack/react-query';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { useCallback } from 'react';
import axios from 'axios';

function useValidateKey() {
  const validateKey = useCallback(async (key: string) => {
    return axios
      .post(`${process.env.ATLAS_SERVICE_URL}/api/contacts/validatekey/${key}`)
      .then((response) => response.data);
  }, []);

  return useMutation({
    mutationFn: validateKey,
    onError: (error) => {
      ai.trackException({
        exception: new Error(`Failed to validate key: ${(error as Error).message}`),
      });
    },
  });
}

export default useValidateKey;
