import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { CompassClient } from 'requests/compassClient';

export const useAssignUserToInvitatedGroup = (compassUrl: string, key: string) => {
  useEffect(() => {
    CompassClient.createInstance(compassUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assignUserToInvitatedGroup = useCallback(async () => {
    const compassClient = CompassClient.Instance;
    const response = await compassClient.post(`/api/profiles/external/assigninvitation`, { key });

    return response.status >= 200 && response.status < 300;
  }, [key]);

  return useMutation({
    mutationFn: assignUserToInvitatedGroup,
    onError: (e) => {
      ai.trackException({
        exception: e,
      });
    },
  });
};
