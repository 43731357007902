import { CustomerTogglesClient } from '@sekoia/shared/requests/nswagClients/TogglesClient';

export class TogglesContextClient {
  private _customerTogglesClient: CustomerTogglesClient | undefined;
  public get customerTogglesClient(): CustomerTogglesClient {
    if (!this._customerTogglesClient) {
      this._customerTogglesClient = new CustomerTogglesClient();
    }
    return this._customerTogglesClient;
  }
}
