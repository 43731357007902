import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Authentication } from './Authentication/Authentication';
import LoadingScreen from '@sekoia/shared/components/LoadingScreen';

const InactivityComponent: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const [state, setState] = useState<'Active' | 'Idle'>('Active');

  useEffect(() => {
    const logout = async () => {
      await Authentication.Instance.logout();
    };
    if (state === 'Idle') logout();
  }, [state]);

  const onIdle = () => {
    setState('Idle');
  };

  useIdleTimer({
    onIdle,
    timeout: 30 * 60 * 1000,
    throttle: 1000,
  });

  if (state === 'Idle') {
    return <LoadingScreen />;
  }

  return <>{props.children}</>;
};

export default InactivityComponent;
