import {
  PnClient,
  PrescriptionClient,
  InventoryClient,
  NotDispensedClient,
  MedicineTaskClient,
  MedicineTypeClient,
} from '@sekoia/shared/requests/nswagClients/MedicineClient';

export class MedicineContextClient {
  private _pnClient: PnClient | undefined;
  public get pnClient(): PnClient {
    if (!this._pnClient) {
      this._pnClient = new PnClient();
    }
    return this._pnClient;
  }

  private _inventoryClient: InventoryClient | undefined;
  public get inventoryClient(): InventoryClient {
    if (!this._inventoryClient) {
      this._inventoryClient = new InventoryClient();
    }
    return this._inventoryClient;
  }

  private _prescriptionClient: PrescriptionClient | undefined;
  public get prescriptionClient(): PrescriptionClient {
    if (!this._prescriptionClient) {
      this._prescriptionClient = new PrescriptionClient();
    }
    return this._prescriptionClient;
  }

  private _notDispensedClient: NotDispensedClient | undefined;
  public get notDispensedClient(): NotDispensedClient {
    if (!this._notDispensedClient) {
      this._notDispensedClient = new NotDispensedClient();
    }
    return this._notDispensedClient;
  }

  private _medicineTaskClient: MedicineTaskClient | undefined;
  public get medicineTaskClient(): MedicineTaskClient {
    if (!this._medicineTaskClient) {
      this._medicineTaskClient = new MedicineTaskClient();
    }
    return this._medicineTaskClient;
  }

  private _medicineTypeClient: MedicineTypeClient | undefined;
  public get medicineTypeClient(): MedicineTypeClient {
    if (!this._medicineTypeClient) {
      this._medicineTypeClient = new MedicineTypeClient();
    }
    return this._medicineTypeClient;
  }
}
