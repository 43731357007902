import createFolder from './create-folder';
import deleteResource from './delete-resource';
import download from './download';
import upload from './upload';
import rename from './rename';
import sort from './sort';

/**
 * Actions' fields list:
 *  showDialog,
 *  hideDialog,
 *  navigateToDir,
 *  updateNotifications,
 *  getSelection,
 *  getSelectedResources,
 *  getResource,
 *  getResourceChildren,
 *  getResourceLocation,
 *  getNotifications,
 *  getSortState
 *
 *  Called from FileNavigator (componentDidMount() and componentWillReceiveProps())
 *
 * @param apiOptions
 * @param {object} actions
 * @returns {array}
 */
const capabilitiesCombined = (apiOptions, actions) => {
  const capabilities = [];
  if (apiOptions.renderUiButtons.createFolder) capabilities.push(createFolder);
  if (apiOptions.renderUiButtons.rename) capabilities.push(rename);
  if (apiOptions.renderUiButtons.download) capabilities.push(download);
  if (apiOptions.renderUiButtons.upload) capabilities.push(upload);
  if (apiOptions.renderUiButtons.deleteResource) capabilities.push(deleteResource);
  if (apiOptions.renderUiButtons.sort) capabilities.push(sort);

  const capabilitiesCombined = capabilities.map((capability) => capability(apiOptions, actions));

  if (apiOptions.customButtons) {
    apiOptions.customButtons.forEach((b) => {
      capabilitiesCombined.push(b);
    });
  }

  return [...capabilitiesCombined];
};

export default capabilitiesCombined;
