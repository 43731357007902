import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ParamsKey } from 'routes/Params';
import { useInvitationStyles } from 'views/external/invitations/styles';
import { useParams } from 'react-router';
import LoadingScreen from 'components/LoadingScreen';
import { Grid, Typography } from '@mui/material';
import useValidateKey from 'hooks/requests/contacts/useValidateKey';
import ResetPassword from 'views/external/reset/ResetPassword';

export const ValidateResetKey: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation(['global', 'external']);
  const classes = useInvitationStyles();
  const { key } = useParams<ParamsKey>();
  const { mutateAsync: validateKey, data: validateData, status: validateKeyStatus } = useValidateKey();

  useEffect(() => {
    validateKey(key);
  }, [key, validateKey]);

  if (validateKeyStatus === 'pending') {
    return <LoadingScreen />;
  }

  if (validateKeyStatus === 'error' || !validateData) {
    return (
      <Grid container className={classes.root} spacing={2} style={{ maxWidth: 500, textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography variant={'h4'}>{t('external:resetPassword.key.invalid.header')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body1'}>{t('external:resetPassword.key.invalid.description')}</Typography>
        </Grid>
      </Grid>
    );
  }

  return <ResetPassword username={validateData.username} />;
};
