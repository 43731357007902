import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
  contentLoader: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.54)',
    zIndex: 2,
  },
}));

type LoadingScreenProps = {
  contentLoader?: boolean;
  width?: number;
  style?: React.CSSProperties;
};

function LoadingScreen({ contentLoader = false, width = 400, style }: LoadingScreenProps) {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={clsx(classes.root, contentLoader && classes.contentLoader)} style={style}>
      <Box width={width}>
        <LinearProgress />
      </Box>
    </div>
  );
}

export default LoadingScreen;
