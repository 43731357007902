import { useAtlasClient } from '@sekoia/shared';
import CacheKeys from '@sekoia/shared/constants/CacheKeys';
import { QueryEmployeesRequest } from '@sekoia/shared/requests/nswagClients/AtlasClient';
import { ElementType } from '@sekoia/shared/utils/TypeScriptHelpers';
import { useCallback } from 'react';
import { useQueryWithRetry } from '@sekoia/shared/hooks/helpers/useQueryWithRetry';

export { EmployeeResponse } from '@sekoia/shared/requests/nswagClients/AtlasClient';
export type Employee = ElementType<ReturnType<typeof useFetchEmployees>['data']>;

function useFetchEmployees(employeeIds?: string[], includeDeleted = false, enabled = true) {
  const { employeeClient } = useAtlasClient();

  const fetchEmployees = useCallback(async () => {
    const request: QueryEmployeesRequest = new QueryEmployeesRequest({ includeDeleted, employeeIds });
    return await employeeClient.queryEmployees(request);
  }, [employeeClient, employeeIds, includeDeleted]);

  return useQueryWithRetry({
    queryKey: employeeIds ? [CacheKeys.EMPLOYEES, employeeIds, includeDeleted] : [CacheKeys.EMPLOYEES],
    queryFn: () => fetchEmployees(),
    enabled,
    gcTime: 5 * 60 * 1000,
  });
}

export default useFetchEmployees;
