import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'constants/Paths';
import ExternalLayout from 'layouts/ExternalLayout';
import InviteToGroup from 'views/external/invitations/InviteToGroup';
import { ProtectedRoute } from 'routes/ProtectedRoute';
import { LoginToInvitation } from 'views/external/invitations/LoginToGroup';
import { ValidateInvitation } from 'views/external/invitations/ValidateInvitation';
import { ValidateResetKey } from 'views/reset/ValidateResetKey';

export const ExternalRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <Route path={Paths.INVITATIONS.href}>
        <ExternalLayout>
          <Switch>
            <ProtectedRoute path={Paths.INVITATIONS_GROUP_LOGIN.href} exact>
              <ValidateInvitation invitationType={'activityGroup'}>
                <LoginToInvitation invitationType={'activityGroup'} />
              </ValidateInvitation>
            </ProtectedRoute>
            <Route path={Paths.INVITATIONS_GROUP.href} exact>
              <ValidateInvitation invitationType={'activityGroup'}>
                <InviteToGroup invitationType={'activityGroup'} />
              </ValidateInvitation>
            </Route>
            <ProtectedRoute path={Paths.INVITATIONS_RELATIVE_LOGIN.href} exact>
              <ValidateInvitation invitationType={'relative'}>
                <LoginToInvitation invitationType={'relative'} />
              </ValidateInvitation>
            </ProtectedRoute>
            <Route path={Paths.INVITATIONS_RELATIVE.href} exact>
              <ValidateInvitation invitationType={'relative'}>
                <InviteToGroup invitationType={'relative'} />
              </ValidateInvitation>
            </Route>
          </Switch>
        </ExternalLayout>
      </Route>
      <Route path={Paths.RESET.href}>
        <ExternalLayout>
          <Switch>
            <Route path={Paths.RESET_PASSWORD.href} exact>
              <ValidateResetKey />
            </Route>
          </Switch>
        </ExternalLayout>
      </Route>
    </>
  );
};
