import { useCallback, useEffect } from 'react';
import { AuthenticationClient } from 'requests/authenticationClient';
import { useQuery } from '@tanstack/react-query';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { Invitation } from './invitations.types';
import { AxiosError } from 'axios';

export const useFetchInvitation = (key: string, enabled: boolean) => {
  useEffect(() => {
    AuthenticationClient.createInstance();
  }, []);

  const fetchInvitation = useCallback(async () => {
    const authClient = AuthenticationClient.Instance;
    const resp = await authClient.get<Invitation>(`/activityGroupInvitations/${key}`);
    return resp.data;
  }, [key]);

  return useQuery<Invitation, AxiosError>({
    queryKey: [`/activityGroupInvitations/${key}`],
    queryFn: () => fetchInvitation(),
    retry: false,
    enabled: enabled,
    throwOnError: (e) => {
      if (e instanceof Error) {
        ai.trackException({
          exception: e,
        });
      } else {
        ai.trackException({
          exception: new Error(`Unknown error occurced while fetching an Invitation: ${JSON.stringify(e)}`),
        });
      }
      return false;
    },
  });
};
