import { useCallback } from 'react';
import CacheKeys from 'constants/CacheKeys';
import { Authentication } from 'utils/Authentication/Authentication';
import { Profile } from 'context/Token/types';
import { useQueryWithRetry } from '@sekoia/shared/hooks/helpers/useQueryWithRetry';

export const useFetchUserInfo = () => {
  const getProfileInformation = useCallback(async () => {
    let authenticationUrl = process.env.LOGIN_SERVICE_URL;
    //TEMP CODE TO TEST NEW AUTH
    const newAuth = localStorage.getItem('newauth');
    if (newAuth && newAuth === 'true' && process.env.LOGIN_SERVICE_URL_V2) {
      authenticationUrl = process.env.LOGIN_SERVICE_URL_V2;
    }
    const response = await fetch(`${authenticationUrl}/connect/userinfo`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${await Authentication.Instance.getToken()}`,
      },
    });
    const data = await response.json();

    return data as Profile;
  }, []);

  return useQueryWithRetry({
    queryKey: [CacheKeys.MY_PROFILE],
    queryFn: () => getProfileInformation(),
  });
};
