import CacheKeys from '@sekoia/shared/constants/CacheKeys';
import { useCallback } from 'react';
import { useDashboardsClient } from '@sekoia/shared/hooks/common/useDashboardsClient';
import { useQueryWithRetry } from '@sekoia/shared/hooks/helpers/useQueryWithRetry';

export const useFetchDashboards = () => {
  const { dashboardsClient } = useDashboardsClient();

  const fetchDashboards = useCallback(async () => {
    return await dashboardsClient.getDashboards();
  }, [dashboardsClient]);

  return useQueryWithRetry({
    queryKey: [CacheKeys.DASHBOARDS],
    queryFn: () => fetchDashboards(),
  });
};
