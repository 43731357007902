import React, { useMemo } from 'react';
import { LocalizationProvider, DateRange } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getLocaleFromDateFns } from '@sekoia/shared/helpers/date-fns/getLocalFromDateFns';
import { Button, Stack } from '@mui/material';

type BasicDateRangePickerProps = {
  onChange: (date: DateRange<Date>) => void;
  value?: DateRange<Date>;
  disableFuture?: boolean;
  onIsInvalid?: (isInValid: boolean) => void;
  allowedNumberOfMonths?: number;
  showClearButton?: boolean;
  smallSize?: boolean;
  acceptEmptyDates?: boolean;
};

const BasicDateRangePicker: React.FC<React.PropsWithChildren<BasicDateRangePickerProps>> = (props) => {
  const {
    onChange,
    value,
    disableFuture,
    onIsInvalid,
    allowedNumberOfMonths,
    showClearButton = false,
    smallSize,
    acceptEmptyDates = false,
  } = props;
  const { t } = useTranslation(['global']);

  const format = moment.localeData().longDateFormat('L').replace(/Y/g, 'y').replace(/D/g, 'd');

  const errorType = useMemo(() => {
    if (!value) return;
    const diff = Math.abs(moment(value[0]).diff(moment(value[1]), 'months'));
    let error = undefined;
    if (value[0] !== null || value[1] !== null) {
      if (value[0] === null || isNaN(value[0].getTime()) || value[1] === null || isNaN(value[1].getTime())) {
        error = 'date';
      }
      if (!error) {
        if (allowedNumberOfMonths && diff > allowedNumberOfMonths) {
          error = 'range';
        } else if (allowedNumberOfMonths && diff === allowedNumberOfMonths) {
          const startDate = value[0]?.getDate();
          const endDate = value[1]?.getDate();
          if (startDate && endDate && endDate > startDate) {
            error = 'range';
          }
        } else if (value[0] && value[1] && value[0] > value[1]) {
          error = 'date';
        }
      }
    } else {
      if (!acceptEmptyDates) error = 'date';
    }
    onIsInvalid && onIsInvalid(error !== undefined);
    return error;
  }, [value, onIsInvalid, allowedNumberOfMonths, acceptEmptyDates]);

  const handleDateRangeUpdated = (selectedDateRange: DateRange<Date>) => {
    onChange(selectedDateRange);
  };

  const handleClearDateRange = () => {
    onChange([null, null]);
  };

  return (
    <Stack direction="row" alignContent={'center'} sx={{ marginTop: '8px', maxWidth: smallSize ? 326 : undefined }}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getLocaleFromDateFns()}
        localeText={{ start: t('global:inputs.date.picker.startDate'), end: t('global:inputs.date.picker.endDate') }}
      >
        <DateRangePicker
          sx={{ width: '100%' }}
          value={value}
          format={format}
          onChange={handleDateRangeUpdated}
          disableFuture={disableFuture}
          slotProps={{
            textField: {
              margin: 'dense',
              error: errorType !== undefined,
              helperText:
                errorType !== undefined
                  ? errorType === 'range'
                    ? t('global:inputs.date.maxInterval')
                    : t('global:inputs.date.invalidDate')
                  : '',
              size: smallSize ? 'small' : 'medium',
            },
            fieldSeparator: { children: t('inputs.date.picker.to') },
          }}
        />
      </LocalizationProvider>
      {showClearButton && (
        <Button variant="text" component="span" onClick={handleClearDateRange} title={t('global:inputs.date.clear')}>
          {t('global:inputs.date.clear')}
        </Button>
      )}
    </Stack>
  );
};

export default BasicDateRangePicker;
