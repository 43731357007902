import React from 'react';
import Avatar from '@mui/material/Avatar';
import LocalHotel from '@mui/icons-material/LocalHotel';

type Props = {
  avatarSize?: string;
  backgroundColor: string;
};

function ApartmentAvatar(props: Props) {
  return (
    <Avatar style={{ backgroundColor: props.backgroundColor }} sizes={props.avatarSize ? props.avatarSize : '40px'}>
      <LocalHotel />
    </Avatar>
  );
}

export default ApartmentAvatar;
