import React, { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { FileNavigator } from '@opuscapita/react-filemanager';
import filesConnector from '../../files';
import { Box, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ApiOptions, CustomButton, FileManagerFileOrDirectoryResource } from '../../files/utils/common';
import OverwriteDialog from './OverwriteDialog/OverwriteDialog';
import { AxiosInstance } from 'axios';
import { TFunction } from 'i18next';
import { TelemetryService } from '@sekoia/shared/utils/telemetryService';

let overwriteFileCallback: () => Promise<void>;

type SekoiaLibraryFileManagerProps = {
  residentId: string;
  residentFullName: string;
  apiRoot: string;
  translations: TFunction;
  filesClient: () => Promise<AxiosInstance>;
  appInsights: TelemetryService;
  renderUiButtons: {
    createFolder: boolean;
    rename: boolean;
    download: boolean;
    upload: boolean;
    deleteResource: boolean;
    sort: boolean;
  };
  permissionsUiActions: {
    canListChildren: boolean;
    canAddChildren: boolean;
    canRemoveChildren: boolean;
    canDelete: boolean;
    canRename: boolean;
    canCopy: boolean;
    canEdit: boolean;
    canDownload: boolean;
  };
  setSelectedResourceHandler?(rowData: FileManagerFileOrDirectoryResource): void;
  doubleClickResourceHandler?(): void;
  customButtons?: CustomButton[];
};

function SekoiaLibraryFileManager(props: SekoiaLibraryFileManagerProps) {
  const t = props.translations;
  const [fileNavigatorFailed, setFileNavigatorFailed] = useState(false);
  const [showOverwriteConfirmDialog, setShowOverwriteConfirmDialog] = React.useState(false);

  //an untranslated text from the framework is translated using css to ensure the translation when present
  const classes = makeStyles({
    root: {
      '& .oc-fm--no-files-found-stub__title': {
        visibility: 'hidden',
        textAlign: 'center',
        '&:after': {
          content: `"${t('files:emptyStateText')}"`,
          visibility: 'visible',
          display: 'block',
        },
      },
      '& .oc-fm--no-files-found-stub__sub-title': {
        visibility: 'hidden',
      },
      '& .oc-fm--file-navigator__notifications': {
        width: 'auto',
        minWidth: 280,
      },
      '& .oc-fm--list-view__row--selected': {
        backgroundColor: '#24955e',
      },
      '& .oc-fm--dialog__button--primary': {
        backgroundColor: '#24955e',
        '&:hover': {
          backgroundColor: '#228555',
        },
      },
      '& .oc-fm--dialog__button[disabled]': {
        backgroundColor: '#ddd',
        '&:hover': {
          backgroundColor: '#ddd',
        },
      },
    },
  })();

  function apiErrorHandler() {
    setFileNavigatorFailed(true);
  }

  function resourceClicked(rowData: FileManagerFileOrDirectoryResource) {
    setSelectedResource(rowData);
  }

  function resourceDoubleClicked(rowData: FileManagerFileOrDirectoryResource) {
    setSelectedResource(rowData);

    const { doubleClickResourceHandler } = props;
    if (doubleClickResourceHandler) doubleClickResourceHandler();
  }

  function setSelectedResource(rowData: FileManagerFileOrDirectoryResource) {
    const { setSelectedResourceHandler } = props;
    if (setSelectedResourceHandler) setSelectedResourceHandler(rowData);
  }

  const handleShowOverwriteConfirmDialog = (state: boolean) => {
    setShowOverwriteConfirmDialog(state);
  };

  const apiOptions: ApiOptions = {
    apiRoot: props.apiRoot,
    ownerId: props.residentId,
    residentFullName: props.residentFullName,
    errorHandler: apiErrorHandler,
    openReplaceFileDialogHandler: (callBack: () => Promise<void>) => {
      overwriteFileCallback = callBack;
      handleShowOverwriteConfirmDialog(true);
    },
    closeReplaceFileDialogHandler: () => handleShowOverwriteConfirmDialog(false),
    translations: t,
    filesClient: props.filesClient,
    appInsights: props.appInsights,
    renderUiButtons: props.renderUiButtons,
    permissionsUiActions: props.permissionsUiActions,
    customButtons: props.customButtons ? props.customButtons : null,
  };

  return (
    <div
      style={{
        height: `100%`,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: 14,
      }}
    >
      <Box style={{ height: '100%' }}>
        {fileNavigatorFailed ? (
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Typography color="inherit" variant="h6">
              {t('global:errorLoading')}
            </Typography>
            <Button
              color="secondary"
              onClick={() => {
                setFileNavigatorFailed(false);
              }}
            >
              {t('global:tryAgain')}
            </Button>
          </Box>
        ) : (
          <div style={{ height: '100%' }} className={classes.root}>
            <FileNavigator
              id="filemanager-1"
              initialResourceId={'root'}
              api={filesConnector.api}
              apiOptions={apiOptions}
              capabilities={filesConnector.capabilities}
              listViewLayout={filesConnector.listViewLayout}
              viewLayoutOptions={filesConnector.viewLayoutOptions}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onResourceItemClick={({ rowData }: any) => resourceClicked(rowData)}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onResourceItemDoubleClick={({ rowData }: any) => resourceDoubleClicked(rowData)}
            />
            <OverwriteDialog
              openDialog={showOverwriteConfirmDialog}
              overwriteFileCallback={overwriteFileCallback}
              handleShowOverwriteConfirmDialog={handleShowOverwriteConfirmDialog}
              translations={props.translations}
            />
          </div>
        )}
      </Box>
    </div>
  );
}

export default React.memo(SekoiaLibraryFileManager);
