import {
  FormClient,
  RegistrationClient,
  AssignmentClient,
  ChoiceClient,
  StepClient,
  FolderClient,
  AnswerClient,
} from '@sekoia/shared/requests/nswagClients/ObservationsClient';

export class ObservationsContextClient {
  private _formClient: FormClient | undefined;
  public get formClient(): FormClient {
    if (!this._formClient) {
      this._formClient = new FormClient();
    }
    return this._formClient;
  }

  private _registrationClient: RegistrationClient | undefined;
  public get registrationClient(): RegistrationClient {
    if (!this._registrationClient) {
      this._registrationClient = new RegistrationClient();
    }
    return this._registrationClient;
  }

  private _choiceClient: ChoiceClient | undefined;
  public get choiceClient(): ChoiceClient {
    if (!this._choiceClient) {
      this._choiceClient = new ChoiceClient();
    }

    return this._choiceClient;
  }

  private _assignmentClient: AssignmentClient | undefined;
  public get assignmentClient(): AssignmentClient {
    if (!this._assignmentClient) {
      this._assignmentClient = new AssignmentClient();
    }

    return this._assignmentClient;
  }

  private _stepClient: StepClient | undefined;
  public get stepClient(): StepClient {
    if (!this._stepClient) {
      this._stepClient = new StepClient();
    }

    return this._stepClient;
  }

  private _folderClient: FolderClient | undefined;
  public get folderClient(): FolderClient {
    if (!this._folderClient) {
      this._folderClient = new FolderClient();
    }

    return this._folderClient;
  }

  private _answerClient: AnswerClient | undefined;
  public get answerClient(): AnswerClient {
    if (!this._answerClient) {
      this._answerClient = new AnswerClient();
    }

    return this._answerClient;
  }
}
