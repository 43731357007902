import { useContext } from 'react';
import { RestClientsContext } from '../../contexts/RestClients/RestClientsContext';
import { ai } from '@sekoia/shared/utils/telemetryService';

export const useRecordsClient = () => {
  const { records } = useContext(RestClientsContext);

  if (!records) {
    ai.trackException(
      `No Records client found, did you forget to wrap the application with a <RestClientsProvider />?`,
    );
    throw new Error(`No Records client found, did you forget to wrap the application with '<RestClientsProvider />?`);
  }

  return records;
};
