import {
  MeasurementTypeClient,
  BloodGlucoseClient,
  BodyTemperatureClient,
  BodyWeightClient,
  HeartRateClient,
  HeightClient,
  BloodPressureClient,
  RespiratoryRateClient,
  OxygenSaturationClient,
} from '@sekoia/shared/requests/nswagClients/HealthMeasurementsClient';

export class HealthMeasurementsContextClient {
  private _measurementTypeClient: MeasurementTypeClient | undefined;
  public get measurementTypeClient(): MeasurementTypeClient {
    if (!this._measurementTypeClient) {
      this._measurementTypeClient = new MeasurementTypeClient();
    }
    return this._measurementTypeClient;
  }

  private _bloodGlucoseClient: BloodGlucoseClient | undefined;
  public get bloodGlucoseClient(): BloodGlucoseClient {
    if (!this._bloodGlucoseClient) {
      this._bloodGlucoseClient = new BloodGlucoseClient();
    }
    return this._bloodGlucoseClient;
  }

  private _bodyTemperatureClient: BodyTemperatureClient | undefined;
  public get bodyTemperatureClient(): BodyTemperatureClient {
    if (!this._bodyTemperatureClient) {
      this._bodyTemperatureClient = new BodyTemperatureClient();
    }
    return this._bodyTemperatureClient;
  }
  private _bodyWeightClient: BodyWeightClient | undefined;
  public get bodyWeightClient(): BodyWeightClient {
    if (!this._bodyWeightClient) {
      this._bodyWeightClient = new BodyWeightClient();
    }
    return this._bodyWeightClient;
  }
  private _heartRateClient: HeartRateClient | undefined;
  public get heartRateClient(): HeartRateClient {
    if (!this._heartRateClient) {
      this._heartRateClient = new HeartRateClient();
    }
    return this._heartRateClient;
  }
  private _heightClient: HeightClient | undefined;
  public get heightClient(): HeightClient {
    if (!this._heightClient) {
      this._heightClient = new HeightClient();
    }
    return this._heightClient;
  }
  private _bloodPressureClient: BloodPressureClient | undefined;
  public get bloodPressureClient(): BloodPressureClient {
    if (!this._bloodPressureClient) {
      this._bloodPressureClient = new BloodPressureClient();
    }
    return this._bloodPressureClient;
  }
  private _respiratoryRateClient: RespiratoryRateClient | undefined;
  public get respiratoryRateClient(): RespiratoryRateClient {
    if (!this._respiratoryRateClient) {
      this._respiratoryRateClient = new RespiratoryRateClient();
    }
    return this._respiratoryRateClient;
  }
  private _oxygenSaturationClient: OxygenSaturationClient | undefined;
  public get oxygenSaturationClient(): OxygenSaturationClient {
    if (!this._oxygenSaturationClient) {
      this._oxygenSaturationClient = new OxygenSaturationClient();
    }
    return this._oxygenSaturationClient;
  }
}
