import { ConversationClient, MessageClient } from '@sekoia/shared/requests/nswagClients/ChinabookClient';

export class ChinabookContextClient {
  private _conversationClient: ConversationClient | undefined;
  public get conversationClient(): ConversationClient {
    if (!this._conversationClient) {
      this._conversationClient = new ConversationClient();
    }
    return this._conversationClient;
  }

  private _messageClient: MessageClient | undefined;
  public get messageClient(): MessageClient {
    if (!this._messageClient) {
      this._messageClient = new MessageClient();
    }
    return this._messageClient;
  }
}
