import ErrorPage from 'components/ErrorPage';
import React from 'react';
import { useTranslation } from 'react-i18next';

function BaseError404View() {
  const { t } = useTranslation(['global']);
  return (
    <ErrorPage
      buttonText={t('backToHome')}
      shouldReload={false}
      title={t('404ErrorTitle')}
      description={t('404ErrorDescription')}
    />
  );
}

export default BaseError404View;
