import CacheKeys from 'constants/CacheKeys';
import { getResidentsForCustomer } from '@sekoia/shared/requests/atlas/service/atlasServiceClient';
import { useQueryWithRetry } from '@sekoia/shared/hooks/helpers/useQueryWithRetry';

function useFetchResidents(enabled = true) {
  return useQueryWithRetry({
    queryKey: [CacheKeys.RESIDENTS],
    queryFn: () => getResidentsForCustomer(),
    enabled,
  });
}

export default useFetchResidents;
