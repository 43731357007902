import ErrorPage from 'components/ErrorPage';
import LoadingScreen from 'components/LoadingScreen';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Authentication } from 'utils/Authentication/Authentication';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { roles } from '@sekoia/shared/domain/User/roles';

type TokenContext = {
  customerId: string;
  customerUrl: string;
  roles: string[];
  accessControlTags: string[];
  professionLogoTypeId: number;
  accessControlCheck: (accessControlTagIds: string[]) => boolean;
  cultureInfoCode: string;
  globalId: string;
};

type DecodedTokenInformation = {
  customerId: string;
  customerUrl: string;
  roles: string[];
  accessControlTags: string[];
  professionLogoTypeId: number;
  cultureInfoCode: string;
  globalId: string;
};

const TokenContext = React.createContext<TokenContext>(null as never);
export const useTokenContext = () => {
  const token = useContext(TokenContext);
  if (!token) throw new Error('No token found, should never happend!');

  return token;
};

export const TokenProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const [isLoadingToken, setIsLoadingToken] = useState(true);
  const [error, setError] = useState(false);
  const [decodedTokenInformation, setDecodedTokenInformation] = useState<DecodedTokenInformation>();

  const { t } = useTranslation(['global']);

  useEffect(() => {
    Authentication.Instance.getProfileInformationFromToken()
      .then((profile) => {
        if (!profile || !profile.customerId || !profile.compassUrl) {
          throw Error(`CustomerId or compassUrl is not in the claim, should not happend!`);
        }

        setDecodedTokenInformation({
          customerId: profile.customerId,
          customerUrl: profile.compassUrl,
          roles: Array.isArray(profile.roles) ? profile.roles : [profile.roles],
          accessControlTags: profile.accessControlTags,
          professionLogoTypeId: Number(profile.professionLogoTypeId),
          cultureInfoCode: profile.cultureInfoCode,
          globalId: profile.globalId,
        });
      })
      .catch((e) => {
        setError(true);
        ai.trackException({
          exception: e,
        });
      })
      .finally(() => {
        setIsLoadingToken(false);
      });
  }, []);

  const accessControlCheck = useCallback(
    (accessControlTagIds: string[]) => {
      if (accessControlTagIds.length === 0) {
        return true;
      }

      if (decodedTokenInformation) {
        const profileRoles = Array.isArray(decodedTokenInformation.roles)
          ? decodedTokenInformation.roles.filter((r) => !parseInt(r))
          : [decodedTokenInformation.roles].filter((r) => !parseInt(r));

        const isSystemAdmin = profileRoles.some((r) => r === roles.systemAdministrator.name);
        if (isSystemAdmin) {
          return true;
        }

        for (const accessControlTagId of decodedTokenInformation.accessControlTags) {
          if (accessControlTagIds.indexOf(accessControlTagId) > -1) {
            return true;
          }
        }
      }

      return false;
    },
    [decodedTokenInformation],
  );

  if (error) {
    return (
      <ErrorPage
        title={t('fullscreenErrorTitle')}
        description={'fullscreenErrorDescription'}
        buttonText={t('tryAgain')}
        shouldReload={true}
      />
    );
  }

  if (isLoadingToken || !decodedTokenInformation) {
    return <LoadingScreen />;
  }

  return (
    <TokenContext.Provider
      value={{
        customerId: decodedTokenInformation.customerId,
        customerUrl: decodedTokenInformation.customerUrl,
        roles: decodedTokenInformation.roles,
        accessControlTags: decodedTokenInformation.accessControlTags,
        professionLogoTypeId: decodedTokenInformation.professionLogoTypeId,
        accessControlCheck,
        globalId: decodedTokenInformation.globalId,
        cultureInfoCode: decodedTokenInformation.cultureInfoCode,
      }}
    >
      {props.children}
    </TokenContext.Provider>
  );
};
