import { useAtlasClient } from '@sekoia/shared/hooks/common/useAtlasClient';
import { useCallback } from 'react';
import CacheKeys from '@sekoia/shared/constants/CacheKeys';
import { useQueryWithRetry } from '@sekoia/shared/hooks/helpers/useQueryWithRetry';

export const useFetchProfessions = (cultureInfoCode: string) => {
  const { professionClient } = useAtlasClient();

  const fetchProfessions = useCallback(async () => {
    const response = await professionClient.getProfessions(cultureInfoCode);
    return response;
  }, [professionClient, cultureInfoCode]);

  return useQueryWithRetry({
    queryKey: [CacheKeys.PROFESSIONS, cultureInfoCode],
    queryFn: () => fetchProfessions(),
    gcTime: 15 * 60 * 1000, // 15 minutes,
  });
};
