import LoadingScreen from 'components/LoadingScreen';
import { useTokenContext } from 'context/Token';
import React, { useCallback, useEffect, useState } from 'react';
import { CustomerTogglesClient } from '@sekoia/shared/requests/nswagClients/TogglesClient';
import { Toggle } from 'requests/toggleServiceClient';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { TogglesContext } from './TogglesContext';

export const TogglesProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [toggles, setToggles] = useState<Toggle[]>();

  const { customerId } = useTokenContext();

  const [customerTogglesClient] = useState(() => new CustomerTogglesClient());

  const getAllToggles = useCallback(async () => {
    const customerToggles = await customerTogglesClient.getCustomerToggles(customerId);

    return customerToggles.map((t) => new Toggle(t));
  }, [customerId, customerTogglesClient]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    getAllToggles()
      .then((toggles) => {
        setToggles(toggles);
      })
      .catch((e) => {
        if (e instanceof Error) {
          ai.trackException({
            exception: e,
          });
        } else {
          ai.trackException({
            exception: new Error(`Unknown error while fetching code toggles: ${JSON.stringify(e)}`),
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // On purpose, we do not want this to rerun on isLoading changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, getAllToggles]);

  if (isLoading || toggles === undefined) {
    return <LoadingScreen />;
  }

  return <TogglesContext.Provider value={toggles}>{props.children}</TogglesContext.Provider>;
};
