import React, { useEffect, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompassClient } from 'requests/compassClient';
import { ParamsKey } from 'routes/Params';
import { useInvitationStyles } from 'views/external/invitations/styles';
import { useFetchInvitation } from 'hooks/requests/invitations/useFetchInvitation';
import { useParams } from 'react-router';
import LoadingScreen from 'components/LoadingScreen';
import { Card, CardHeader, Grid } from '@mui/material';
import BaseError404View from 'views/base/BaseError404View';
import { InvitationContext } from './InvitationActivityGroupContext';
import { useFetchRelativeInvitation } from 'hooks/requests/invitations/useFetchRelativeInvitation';
import { InvitationType } from 'hooks/requests/invitations/invitations.types';

type ValidateInvitationProps = {
  invitationType: InvitationType;
  children: ReactNode;
};

export const ValidateInvitation = ({ invitationType, children }: ValidateInvitationProps) => {
  const { t } = useTranslation(['global', 'invitations']);
  const classes = useInvitationStyles();

  const { key } = useParams<ParamsKey>();
  const activityGroup = useFetchInvitation(key, invitationType === 'activityGroup');
  const relative = useFetchRelativeInvitation(key, invitationType === 'relative');

  useEffect(() => {
    // Initiate a compass client with compassUrl from the invitation
    if (activityGroup.isFetched && activityGroup.data && activityGroup.data.compassUrl) {
      CompassClient.createInstance(activityGroup.data.compassUrl);
    } else if (relative.isFetched && relative.data && relative.data.compassUrl) {
      CompassClient.createInstance(relative.data.compassUrl);
    }
  }, [activityGroup.data, activityGroup.isFetched, relative.data, relative.isFetched]);

  const invitationContextData = useMemo(() => {
    return invitationType === 'activityGroup' ? activityGroup.data : relative.data;
  }, [activityGroup.data, invitationType, relative.data]);

  if (
    (invitationType === 'activityGroup' && !activityGroup.isFetched) ||
    (invitationType === 'relative' && !relative.isFetched)
  ) {
    return <LoadingScreen />;
  }

  if (
    (activityGroup.error && activityGroup.error.response?.status === 410) ||
    (relative.error && relative.error.response?.status === 410)
  ) {
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={t('invitations:invalid.header')} subheader={t('invitations:invalid.intro')} />
          </Card>
        </Grid>
      </Grid>
    );
  }

  if (
    (activityGroup.error && activityGroup.error.response?.status === 404) ||
    (relative.error && relative.error.response?.status === 404)
  ) {
    return <BaseError404View />;
  }

  if (
    (invitationType === 'relative' && !relative.data) ||
    (invitationType === 'activityGroup' && !activityGroup.data)
  ) {
    return null;
  }

  return <InvitationContext.Provider value={invitationContextData}>{children}</InvitationContext.Provider>;
};
