import { AxiosInstance } from 'axios';
import { TelemetryService } from '@sekoia/shared/utils/telemetryService';

export enum ResponseType {
  File,
  Directory,
}

export interface DirectoryWithChildrenResponse {
  children: [
    {
      createdAt: string;
      createdTime: string;
      id: string;
      modifiedAt: string;
      name: string;
      parentId: string;
      type: string;
    },
  ];
  createdAt: string;
  createdTime: string;
  id: string;
  modifiedAt: string;
  name: string;
  type: string;
  size?: number;
  ancestors?: Ancestors[];
  parentId?: string;
  fileExtension?: string;
  fileSizeInBytes?: string;
}

export interface GetChildrenArguments {
  id: string;
  sortBy?: 'name' | 'modifiedTime';
  sortDirection?: string;
}

export interface ApiOptions {
  apiRoot: string;
  ownerId: string;
  residentFullName?: string;
  errorHandler(): void;
  openReplaceFileDialogHandler(arg: () => Promise<void>): void;
  closeReplaceFileDialogHandler(): void;
  translations(key: string): string;
  filesClient: () => Promise<AxiosInstance>;
  appInsights?: TelemetryService;
  renderUiButtons: {
    createFolder: boolean;
    rename: boolean;
    download: boolean;
    upload: boolean;
    deleteResource: boolean;
    sort: boolean;
  };
  permissionsUiActions: {
    canListChildren: boolean;
    canAddChildren: boolean;
    canRemoveChildren: boolean;
    canDelete: boolean;
    canRename: boolean;
    canCopy: boolean;
    canEdit: boolean;
    canDownload: boolean;
  };
  customButtons?: CustomButton[] | null;
}

export interface CustomButton {
  id: string;
  icon: {
    svg: string;
  };
  label: string;
  shouldBeAvailable: () => void;
  availableInContexts: string[];
  handler: () => void;
}

export interface Capabilities {
  canListChildren: boolean;
  canAddChildren: boolean;
  canRemoveChildren: boolean;
  canDelete: boolean;
  canRename: boolean;
  canCopy: boolean;
  canEdit: boolean;
  canDownload: boolean;
}

export interface Ancestors {
  createdAt: string;
  createdTime: string;
  id: string;
  modifiedTime: string;
  name: string;
  type: string;
}

export interface BackendFile extends BackendDirectory {
  ancestors: string[];
}

export interface BackendDirectory {
  ownerId: string;
  createdAt: string;
  modifiedTime: string;
  id: string;
  name: string;
  type: string;
  size: number;
  parentId: string;
  ancestors: string[] | null;
}

export interface BackedResponse {
  body: {
    children: BackendDirectory[];
    files: BackendFile[];
    ownerId: string;
  };
}

export interface DownloadResourcesArguments {
  apiOptions: ApiOptions;
  resources: FileManagerFileOrDirectoryResource[];
  onProgress: (progress: number) => void;
}
export interface UploadFileToIdArguments {
  apiOptions: ApiOptions;
  parentId: string;
  file: File;
  onProgress: (progress: number) => void;
  fileId?: string;
}
export interface File {
  file: Blob;
  name: string;
  type: string;
}

export interface FileManagerFileOrDirectoryResource {
  capabilities: Capabilities;
  createdTime: number;
  id: string;
  modifiedTime: number;
  name: string;
  type: string;
  size: number | undefined;
  parentId: string | undefined;
  ancestors?: Ancestors[];
}

export function normalizeResource(
  resource: DirectoryWithChildrenResponse,
  capabilities: Capabilities,
): FileManagerFileOrDirectoryResource {
  if (resource) {
    return {
      capabilities,
      createdTime: Date.parse(resource.createdTime),
      id: resource.id,
      modifiedTime: Date.parse(resource.modifiedAt ? resource.modifiedAt : resource.createdAt),
      name: resource.type === 'file' ? resource.name + resource.fileExtension : resource.name,
      type: resource.type,
      size: resource.fileSizeInBytes ? parseInt(resource.fileSizeInBytes) : undefined,
      ancestors: resource.ancestors ? resource.ancestors : [],
      parentId: resource.parentId,
    };
  } else {
    return {} as FileManagerFileOrDirectoryResource;
  }
}
