import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress.css';
import { enableES5 } from 'immer';
import React, { Suspense } from 'react';
import * as serviceWorker from 'serviceWorker';
import 'i18n';
import LoadingScreen from 'components/LoadingScreen';
import { createTheme } from 'theme/theme';
import { Theme, StyledEngineProvider } from '@mui/material';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { App } from 'App';
import { ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

enableES5();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={createTheme()}>
      <StylesProvider jss={jss}>
        <Suspense fallback={<LoadingScreen />}>
          <App />
        </Suspense>
      </StylesProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
);

serviceWorker.unregister();
