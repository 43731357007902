import { useContext } from 'react';
import { RestClientsContext } from '@sekoia/shared/contexts/RestClients/RestClientsContext';
import { ai } from '@sekoia/shared/utils/telemetryService';

export const useReportsClient = () => {
  const { reports } = useContext(RestClientsContext);

  if (!reports) {
    ai.trackException(
      `No Reports client found, did you forget to wrap the application with a <RestClientsProvider />?`,
    );
    throw new Error(`No Reports client found, did you forget to wrap the application with '<RestClientsProvider />?`);
  }

  return reports;
};
