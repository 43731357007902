import api from './api';
import capabilities from './capabilities';
import listViewLayout from './list-view-layout';
import viewLayoutOptions from './view-layout-options';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  api,
  capabilities,
  listViewLayout,
  viewLayoutOptions,
};
