import api from '../api';
import notifUtils from '../utils/notifications';
import { downloadBlob } from '../utils/downloadBlob';
import onFailError from '../utils/onFailError';
import icons from '../icons-svg';
import getMess from '../translations';

const label = 'download';

async function handler(apiOptions, actions) {
  const { updateNotifications, getSelectedResources, getNotifications } = actions;

  const getMessage = getMess.bind(null, apiOptions.locale);

  const notificationId = label;

  const onStart = async ({ archiveName, quantity, index }) => {
    const notifications = getNotifications();
    const notification = notifUtils.getNotification(notifications, notificationId);

    const childElement = {
      elementType: 'NotificationProgressItem',
      elementProps: {
        title: archiveName,
        progress: 0,
      },
    };

    const childId = notificationId + index;

    const newChildren = notifUtils.addChild((notification && notification.children) || [], childId, childElement);

    const newNotification = {
      title: quantity > 1 ? getMessage('DownloadingItems', { quantity }) : getMessage('DownloadingItem'),
      children: newChildren,
    };

    const newNotifications = notification
      ? notifUtils.updateNotification(notifications, notificationId, newNotification)
      : notifUtils.addNotification(notifications, notificationId, newNotification);
    updateNotifications(newNotifications);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSuccess = (_) => {
    const notifications = getNotifications();
    const newNotifications = notifUtils.removeNotification(notifications, notificationId);
    updateNotifications(newNotifications);
  };

  const getUrlAndDownloadBlob = async (apiOptions, id, name, onProgress, childIndex) => {
    const downloadResponse = await api.getDownloadResourceById(apiOptions, id);
    const downloadUrl = downloadResponse.data.uri;
    await downloadBlob(name, downloadUrl, onProgress, childIndex);
  };

  const onProgress = async (progress, childIndex) => {
    const notifications = getNotifications();
    const notification = notifUtils.getNotification(notifications, notificationId);
    const childId = label + childIndex;
    const child = notifUtils.getChild(notification.children, childId);
    const newChild = {
      ...child,
      element: {
        ...child.element,
        elementProps: {
          ...child.element.elementProps,
          progress,
        },
      },
    };
    const newChildren = notifUtils.updateChild(notification.children, childId, newChild);
    const newNotifications = notifUtils.updateNotification(notifications, notificationId, { children: newChildren });
    updateNotifications(newNotifications);
  };

  const promiseDownload = async (file, quantity, index) => {
    await getUrlAndDownloadBlob(apiOptions, file.id, file.name, onProgress, index);
  };

  try {
    const resources = getSelectedResources();
    const quantity = resources.length;
    for (let index = 0; index < resources.length; index++) {
      const file = resources[index];
      await onStart({ archiveName: file.name, quantity, index });
    }
    const promises = resources.map((f, index) => promiseDownload(f, resources.length, index));
    await Promise.all(promises);
    setTimeout(onSuccess, 1000);
  } catch (err) {
    onFailError({
      appInsights: apiOptions.appInsights,
      getNotifications,
      label: getMessage(label),
      notificationId,
      updateNotifications,
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (apiOptions, actions) => {
  const localeLabel = getMess(apiOptions.locale, label);
  const { getSelectedResources } = actions;
  return {
    id: label,
    icon: { svg: icons.fileDownload },
    label: localeLabel,
    shouldBeAvailable: () => {
      const selectedResources = getSelectedResources();

      return (
        selectedResources.length > 0 &&
        !selectedResources.some((r) => r.type === 'dir') &&
        selectedResources.every((r) => r.capabilities.canDownload)
      );
    },
    availableInContexts: ['row', 'toolbar'],
    handler: () => handler(apiOptions, actions),
  };
};
