class CacheKeys {
  static readonly MY_PROFILE = 'MY_PROFILE';
  static readonly PROFILE = 'PROFILE';
  static readonly RESIDENTS = 'RESIDENTS';
  static readonly RESIDENT_BASIC_INFORMATION = 'RESIDENT_BASIC_INFORMATION';
  static readonly PLANS_HEADING = 'PLANS_HEADING';
  static readonly POSSIBLE_MARITAL_STATUS = 'POSSIBLE_MARITAL_STATUS';
  static readonly LOCATIONS_WITH_RESIDENTS = 'LOCATIONS_WITH_RESIDENTS';
  static readonly EMPLOYEE = 'EMPLOYEE';
  static readonly EMPLOYEE_CARDS = 'EMPLOYEE_CARDS';
  static readonly EMPLOYEE_ROLES = 'EMPLOYEE_ROLES';
  static readonly EMPLOYEES = 'EMPLOYEES';
  static readonly EMPLOYEE_GROUPS = 'EMPLOYEE_GROUPS';
  static readonly AFFILIATED_ORGANIZATIONS = 'AFFILIATED_ORGANIZATIONS';
  static readonly EMPLOYEES_ORGANIZATIONAL_UNITS = 'EMPLOYEES_ORGANIZATIONAL_UNITS';
  static readonly CONTACTS = 'CONTACTS';
  static readonly INTERNAL_CONTACTS = 'INTERNAL_CONTACTS';
  static readonly STANDARD_FORMS_BY_LOCATION_IDS = 'STANDARD_FORMS_BY_LOCATION_IDS';
  static readonly FORM_ASSIGNMENTS = 'FORM_ASSIGNMENTS';
  static readonly RESIDENT_FORMS = 'RESIDENT_FORMS';
  static readonly RESIDENTS_REGISTRATIONS = 'RESIDENTS_REGISTRATIONS';
  static readonly REGISTRATIONS_WITH_ANSWERS = 'REGISTRATIONS_WITH_ANSWERS';
  static readonly FORM_WITH_STEPS_AND_CHOICES = 'FORM_WITH_STEPS_AND_CHOICES';
  static readonly FORM_ASSOCIATED_PLANS = 'FORM_ASSOCIATED_PLANS';
  static readonly CATEGORIES = 'CATEGORIES';
  static readonly FORM_STEPS = 'FORM_STEPS';
  static readonly FORM_ASSIGN_FOR_FUTURE_RESIDENTS = 'FORM_ASSIGN_FOR_FUTURE_RESIDENTS';
  static readonly IS_RESIDENT_DUPLICATE = 'IS_RESIDENT_DUPLICATE';
  static readonly DEVICE_IDS = 'DEVICE_IDS';
  static readonly STANDARDTASKS_ASSIGNED_TO_RESIDENT = 'STANDARDTASKS_ASSIGNED_TO_RESIDENT';
  static readonly TASK_ASSIGNMENTS = 'TASK_ASSIGNMENTS';
  static readonly ACCESS_CONTROL_TAGS = 'ACCESS_CONTROL_TAGS';
  static readonly EMPLOYEE_ACCESS_CONTROL_TAGS = 'EMPLOYEE_ACCESS_CONTROL_TAGS';
  static readonly HEALTH_MEASUREMENT_TYPES = 'HEALTH_MEASUREMENT_TYPES';
  static readonly FORM_FOLDERS = 'FORM_FOLDERS';
  static readonly TASKENTRIES_FOR_TASK_REPORT = 'TASK_ENTRIES_FOR_TASK_REPORT';
  static readonly INVENTORY_ITEMS = 'INVENTORY_ITEMS';
  static readonly INVENTORY_ITEM = 'INVENTORY_ITEM';
  static readonly INVENTORY_SERVICE_TYPES = 'INVENTORY_SERVICE_TYPES';
  static readonly INVENTORY_ITEM_SERVICES = 'INVENTORY_ITEM_SERVICES';
  static readonly INVENTORY_SOURCED_FROM_TYPES = 'INVENTORY_SOURCED_FROM_TYPES';
  static readonly INVENTORY_ITEMS_FOR_RESIDENT = 'INVENTORY_ITEMS_FOR_RESIDENT';
  static readonly INVENTORY_ITEM_LOAN_HISTORY = 'INVENTORY_ITEM_LOAN_HISTORY';
}

export default CacheKeys;
