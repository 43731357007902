import i18n from 'i18next';

export default function getMessage(locale, key, params) {
  const translations = {
    uploading: i18n.t('files:uploading'),
    uploadingItem: i18n.t('files:uploadingItem'),
    uploadingItems: i18n.t('files:uploadingItems'),
    upload: i18n.t('files:upload'),
    remove: i18n.t('files:remove'),
    download: i18n.t('files:download'),
    rename: i18n.t('files:rename'),
    creating: i18n.t('files:creating'),
    creatingName: i18n.t('files:creatingName'),
    create: i18n.t('files:create'),
    createFolder: i18n.t('files:createFolder'),
    DownloadingItem: i18n.t('files:DownloadingItem'),
    DownloadingItems: i18n.t('files:DownloadingItems'),
    items: i18n.t('files:items'),
    item: i18n.t('files:item'),
    cancel: i18n.t('files:cancel'),
    confirm: i18n.t('files:confirm'),
    folderName: i18n.t('files:folderName'),
    files: i18n.t('files:files'),
    fileExist: i18n.t('files:fileExist'),
    newName: i18n.t('files:newName'),
    emptyName: i18n.t('files:emptyName'),
    tooLongFolderName: i18n.t('files:tooLongFolderName'),
    folderNameNotAllowedCharacters: i18n.t('files:folderNameNotAllowedCharacters'),
    title: i18n.t('files:title'),
    fileSize: i18n.t('files:fileSize'),
    lastModified: i18n.t('files:lastModified'),
    reallyRemove: i18n.t('files:reallyRemove'),
    unableReadDir: i18n.t('files:unableReadDir'),
  };

  const translationExists = translations && translations[key];
  const translation = translationExists ? translations[key] : 'unknown';

  if (!params) {
    return translation;
  }

  const re = /{\w+}/g;
  function replace(match) {
    const replacement = match.slice(1, -1);
    return params[replacement] ? params[replacement] : '';
  }

  return translation.replace(re, replace);
}
