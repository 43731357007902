import { SortDirection } from '@mui/material';
import * as _ from 'lodash';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sortArrayOfObjects<T extends Array<any>>(
  inputArray: T,
  property: keyof T[number],
  direction: SortDirection = 'asc',
) {
  const copy = _.cloneDeep(inputArray);

  copy.sort((a, b) => {
    let left = a[property];
    let right = b[property];

    if (typeof left === 'boolean') {
      left = left ? '1' : '0';
    }
    if (typeof right === 'boolean') {
      right = right ? '1' : '0';
    }

    if (typeof left === 'object' && right === undefined) {
      right = '-';
    }
    if (typeof right === 'object' && left === undefined) {
      left = '-';
    }

    if (!left || !right) {
      return 1;
    }

    if (typeof left === 'number') {
      left = `${left}`;
    }
    if (typeof right === 'number') {
      right = `${right}`;
    }

    if (typeof left === 'object') {
      if (moment.isMoment(left)) {
        left = left.valueOf().toString();
      }
    }
    if (typeof right === 'object') {
      if (moment.isMoment(right)) {
        right = right.valueOf().toString();
      }
    }
    const localeCompareOptions = { sensitivity: 'base', numeric: true };
    if (direction === 'asc') {
      return left.localeCompare(right, window.navigator.language, localeCompareOptions);
    } else {
      return right.localeCompare(left, window.navigator.language, localeCompareOptions);
    }
  });

  return copy;
}

export function sortArrayOfStrings<T extends Array<string>>(inputArray: T, direction: SortDirection = 'asc') {
  const copy = _.cloneDeep(inputArray);

  copy.sort((a, b) => {
    let left = a;
    let right = b;

    if (typeof left === 'boolean') {
      left = left ? '1' : '0';
    }
    if (typeof right === 'boolean') {
      right = right ? '1' : '0';
    }
    if (!left || !right) {
      return 1;
    }

    if (typeof left === 'number') {
      left = `${left}`;
    }
    if (typeof right === 'number') {
      right = `${right}`;
    }

    if (direction === 'asc') {
      return left.localeCompare(right, window.navigator.language, { sensitivity: 'base', numeric: true });
    } else {
      return right.localeCompare(left, window.navigator.language, { sensitivity: 'base', numeric: true });
    }
  });

  return copy;
}
