import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { CompassClient } from 'requests/compassClient';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { CheckRelativeUsernameRequest } from './invitations.types';

export const useDoesUsernameExist = (invitationType: string) => {
  const doesUsernameExist = useCallback(
    async (request: CheckRelativeUsernameRequest): Promise<boolean> => {
      const client = CompassClient.Instance;

      let response;
      if (invitationType === 'activityGroup') {
        response = await client.post(`/api/profiles/external/checkusername`, request);
      } else if (invitationType === 'relative') {
        response = await client.post(`/api/profiles/relatives/checkusername`, request);
      }

      return response?.data === false;
    },
    [invitationType],
  );

  return useMutation({
    mutationFn: doesUsernameExist,
    onError: (e) => {
      ai.trackException({
        exception: e,
      });
    },
  });
};
