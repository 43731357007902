import {
  ApiOptions,
  normalizeResource,
  FileManagerFileOrDirectoryResource,
  GetChildrenArguments,
  Ancestors,
  UploadFileToIdArguments,
  DirectoryWithChildrenResponse,
} from './utils/common';
import { AxiosError } from 'axios';

/**
 * hasSignedIn
 *
 * @returns {boolean}
 */
function hasSignedIn() {
  return true;
}

/**
 * Init API
 *
 * @returns {Promise<{apiInitialized: boolean, apiSignedIn: boolean}>}
 */
function init() {
  return {
    apiInitialized: true,
    apiSignedIn: true,
  };
}

async function getCapabilitiesForResource(_options: ApiOptions, resource: FileManagerFileOrDirectoryResource) {
  return resource.capabilities || [];
}

async function getResourceById(options: ApiOptions, id: string) {
  const residentFullName = options.residentFullName
    ? options.residentFullName
    : options.translations('files:rootFolder');
  const path = `${options.apiRoot}/api/owner/${options.ownerId}/${
    id !== 'root' && id !== undefined ? 'directory/' + id : 'directory'
  }`;
  const axios = await options.filesClient();
  try {
    const response = await axios.get(path);

    if (response.status === 200) {
      const fileManagerFileOrDirectoryResource = normalizeResource(
        response.data,
        options.permissionsUiActions,
      ) as FileManagerFileOrDirectoryResource;

      if (!fileManagerFileOrDirectoryResource.id) fileManagerFileOrDirectoryResource.name = residentFullName;

      //always adding root to ancestors
      const root: Ancestors = {
        createdAt: '',
        createdTime: '',
        id: '',
        modifiedTime: '',
        name: residentFullName,
        type: 'dir',
      };
      fileManagerFileOrDirectoryResource.ancestors?.unshift(root);
      return fileManagerFileOrDirectoryResource;
    }
  } catch (error) {
    options.errorHandler();
    options.appInsights?.trackException({
      exception: new Error(`Failed in returning resource : ${(error as Error).message}`),
    });

    return error;
  }
}

async function getChildrenForId(
  options: ApiOptions,
  { id, sortBy = 'name', sortDirection = 'ASC' }: GetChildrenArguments,
) {
  const path = `${options.apiRoot}/api/owner/${options.ownerId}/${
    id !== 'root' && id !== undefined ? 'directory/' + id : 'directory'
  }`;
  const axios = await options.filesClient();
  try {
    const response = await axios.get(path);
    if (response.status === 200) {
      let responseToSort: FileManagerFileOrDirectoryResource[];
      if (id) {
        responseToSort = response.data.children.map((c: DirectoryWithChildrenResponse) =>
          normalizeResource(c, options.permissionsUiActions),
        );
      } else {
        responseToSort = response.data.map((c: DirectoryWithChildrenResponse) =>
          normalizeResource(c, options.permissionsUiActions),
        );
      }
      //First sort, using sortBy representing the column
      return (
        responseToSort
          .sort((l: FileManagerFileOrDirectoryResource, r: FileManagerFileOrDirectoryResource) => {
            if (sortDirection === 'ASC') {
              return l[sortBy].toString().localeCompare(r[sortBy].toString());
            } else {
              return r[sortBy].toString().localeCompare(l[sortBy].toString());
            }
          })
          //Second sort, ordering files after/before folders
          .sort((l: FileManagerFileOrDirectoryResource, r: FileManagerFileOrDirectoryResource) => {
            if (sortDirection === 'ASC') return l.type.localeCompare(r.type);
            else return r.type.localeCompare(l.type);
          })
      );
    }
  } catch (error) {
    options.errorHandler();
    if ((error as AxiosError).response?.status !== 404) {
      options.appInsights?.trackException({
        exception: new Error(`Failed in returning directory children : ${(error as Error).message}`),
      });
    }

    return error;
  }
}

async function getParentsForId(options: ApiOptions, id: string, result = []) {
  if (!id) {
    return result;
  }

  const resource = (await getResourceById(options, id)) as FileManagerFileOrDirectoryResource;
  if (resource && resource.ancestors) {
    return resource.ancestors;
  }
  return result;
}

async function getParentIdForResource(_options: ApiOptions, resource: FileManagerFileOrDirectoryResource) {
  return resource.parentId;
}

async function uploadFile({ apiOptions, parentId, file, onProgress }: UploadFileToIdArguments) {
  const path = `${apiOptions.apiRoot}/api/owner/${apiOptions.ownerId}/file`;

  const formData = new FormData();
  formData.append('File', file.file);
  if (parentId) formData.append('DirectoryParentId', parentId);

  const axios = await apiOptions.filesClient();
  const request = await axios.post(path, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onUploadProgress: function (progressEvent: any) {
      const percentageCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onProgress(percentageCompleted);
    },
  });
  return request;
}

async function overWriteFile({ apiOptions, parentId, file, fileId, onProgress }: UploadFileToIdArguments) {
  const path = `${apiOptions.apiRoot}/api/owner/${apiOptions.ownerId}/file/${fileId}`;

  const formData = new FormData();
  formData.append('File', file.file);
  if (parentId) formData.append('DirectoryParentId', parentId);

  const axios = await apiOptions.filesClient();
  const request = await axios.post(path, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onUploadProgress: function (progressEvent: any) {
      const percentageCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onProgress(percentageCompleted);
    },
  });
  return request;
}

async function createFolder(options: ApiOptions, parentId: string, folderName: string) {
  const route = `${options.apiRoot}/api/owner/${options.ownerId}/directory`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await options.filesClient().then((axios: any) => {
    return axios.post(route, {
      directoryName: folderName,
      parentId: parentId,
    });
  });
  return response;
}

function getResourceName(_apiOptions: ApiOptions, resource: FileManagerFileOrDirectoryResource) {
  return resource.name;
}

async function renameResource(
  options: ApiOptions,
  id: string,
  newName: string,
  resource: FileManagerFileOrDirectoryResource,
) {
  const path = `${options.apiRoot}/api/owner/${options.ownerId}/${
    resource.type === 'dir' ? 'directory' : 'file'
  }/${id}`;
  const axios = await options.filesClient();
  let response;
  if (resource.type === 'dir')
    response = await axios.patch(path, {
      directoryName: newName,
    });
  else
    response = await axios.patch(path, {
      fileName: newName,
    });
  if (response.status === 200) {
    return response;
  }
}

async function removeResource(options: ApiOptions, resource: FileManagerFileOrDirectoryResource) {
  const route = `${options.apiRoot}/api/owner/${options.ownerId}/${resource.type === 'dir' ? 'directory' : 'files'}/${
    resource.id
  }`;
  const axios = await options.filesClient();
  const response = await axios.delete(route);
  return response;
}

async function removeResources(options: ApiOptions, selectedResources: FileManagerFileOrDirectoryResource[]) {
  return Promise.all(selectedResources.map((resource) => removeResource(options, resource)));
}

async function getDownloadResourceById(options: ApiOptions, resourceId: string) {
  const path = `${options.apiRoot}/api/owner/${options.ownerId}/file/${resourceId}/download`;
  const axios = await options.filesClient();
  const response = await axios.get(path).catch((e: AxiosError) => {
    return e;
  });
  return response;
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  init,
  hasSignedIn,
  getResourceById,
  getCapabilitiesForResource,
  getChildrenForId,
  getParentsForId,
  getParentIdForResource,
  getResourceName,
  createFolder,
  renameResource,
  removeResources,
  uploadFile,
  overWriteFile,
  getDownloadResourceById,
};
