import { Grid, Box, Typography, Button, Divider } from '@mui/material';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ParamsKey } from 'routes/Params';
import CreateUser from './CreateUser';
import { generatePath, useHistory } from 'react-router-dom';
import { Paths } from 'constants/Paths';
import { useParams } from 'react-router';
import { useInvitationStyles } from 'views/external/invitations/styles';
import { InvitationContext } from 'views/external/invitations/InvitationActivityGroupContext';
import { InvitationType } from 'hooks/requests/invitations/invitations.types';

type InviteGroupProps = {
  invitationType: InvitationType;
};

const InviteToGroup = ({ invitationType }: InviteGroupProps) => {
  const { t } = useTranslation(['global', 'invitations']);
  const classes = useInvitationStyles();
  const history = useHistory();
  const { key } = useParams<ParamsKey>();
  const invitation = useContext(InvitationContext);

  if (!invitation) {
    throw Error(`No invitation validated!`);
  }

  const handleOnLoginClick = useCallback(() => {
    let nextPath;
    if (invitationType === 'activityGroup') {
      nextPath = generatePath(Paths.INVITATIONS_GROUP_LOGIN.href, {
        key,
      });
    } else if (invitationType === 'relative') {
      nextPath = generatePath(Paths.INVITATIONS_RELATIVE_LOGIN.href, {
        key,
      });
    }
    if (!nextPath)
      throw Error('Should never happend because invitationType should be either activityGroup or relative.');

    history.push(nextPath);
  }, [history, invitationType, key]);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <CreateUser invitationKey={key} invitationType={invitationType} invitation={invitation} />
      </Grid>
      <Grid item xs={12}>
        <Box textAlign={'center'} m={3}>
          <Divider />
          <Typography variant={'h4'} color={'textPrimary'} gutterBottom className={classes.topMargin}>
            {t('invitations:group.alreadyUser')}
          </Typography>
          <Button color={'primary'} variant={'contained'} className={classes.topMargin} onClick={handleOnLoginClick}>
            {t('invitations:group.logon')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default InviteToGroup;
