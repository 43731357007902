import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

interface Props extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  /** Page title */
  title: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
  },
}));

type Ref = HTMLDivElement;
export const Page = React.forwardRef<Ref, Props>((props, ref) => {
  const classes = useStyles();
  const { title, children, ...rest } = props;

  return (
    <div ref={ref} {...rest} className={classes.root}>
      <Helmet>
        <title>{title} - Sekoia</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  title: PropTypes.string.isRequired,
};
Page.displayName = 'ComponentPage';
