import {
  ResidentClient,
  IResidentResponse,
  MaritalStatusClient,
  ResidentResponse,
  UkResidentResponse,
  SwedenResidentResponse,
  GetResidentsRequest,
} from '@sekoia/shared/requests/nswagClients/AtlasClient';
import moment from 'moment';

export const getResidentsForCustomer = async (): Promise<Resident[]> => {
  const client = new ResidentClient();

  const getResidentsRequest = new GetResidentsRequest({
    residentIds: undefined,
    includeDeleted: false,
    includeLengthOfStay: false,
  });

  const result = await client.getResidents(getResidentsRequest);
  const residents = result.map((resident) => normalizeResident(resident));
  return residents;
};

export const getResidentById = async (_: string, residentId: string): Promise<Resident> => {
  const client = new ResidentClient();

  const resident = await client.findResident(residentId, false, undefined);

  return normalizeResident(resident);
};

export const getPossibleMaritalStatuses = async (): Promise<string[]> => {
  const client = new MaritalStatusClient();
  const result = await client.getPossibleMaritalStatuses();

  return result;
};

export interface Resident extends IResidentResponse {
  fullName: string;
  nhs?: string;
  admission?: moment.Moment;
  personnummer?: string;
}

function normalizeResident(residentResponse: ResidentResponse | UkResidentResponse | SwedenResidentResponse): Resident {
  const isResident = residentResponse instanceof ResidentResponse;
  const isUkResident = residentResponse instanceof UkResidentResponse;
  const isSwedishResident = residentResponse instanceof SwedenResidentResponse;

  if (isResident || isUkResident || isSwedishResident) {
    return { ...residentResponse, fullName: `${residentResponse.firstname} ${residentResponse.lastname}` };
  } else {
    return {} as Resident;
  }
}
