import { getIcon } from './icons';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  locale: 'en',
  initialSortBy: 'name',
  initialSortDirection: 'ASC',
  humanReadableSize: true,
  getIcon,
};
