// Moment locales
import 'moment/locale/da';
import 'moment/locale/en-gb';
import 'moment/locale/sv';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/nb';

import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { Button } from '@mui/material';
import moment from 'moment';
import { Router } from 'routes/Router';
import { restoreSettings } from 'utils/settings';
import { SettingsProvider } from 'context/SettingsContext';
import { QueryClientProvider } from '@tanstack/react-query';
import { useLocation } from 'react-router';
import queryClient from 'queryClient';
import InactivityComponent from 'utils/InactivityComponent';

// Set en-gb as default locale, if locale in users browser is not supported.
moment.locale('en-gb');
moment.locale(window.navigator.language);

const settings = restoreSettings();
export const Bootstrap: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation(['global']);
  const notistackRef = React.useRef<SnackbarProvider>(null);
  const { search: querystring } = useLocation();

  //TEMP CODE TO TEST NEW AUTH
  //Simple helper function check to set or remove newauth from localstorage
  useEffect(() => {
    if (querystring === '?newauth=true') localStorage.setItem('newauth', 'true');
    if (querystring === '?newauth=false') localStorage.removeItem('newauth');
  }, [querystring]);

  const onClickDismiss = useCallback(
    (key: SnackbarKey) => () => {
      if (notistackRef.current !== null && notistackRef.current !== undefined) {
        notistackRef.current.closeSnackbar(key);
      }
    },
    [],
  );

  const snackbarDefaultAction = useCallback(
    (key: SnackbarKey) => {
      return (
        <Button onClick={onClickDismiss(key)} style={{ color: 'white' }}>
          {t('global:close')}
        </Button>
      );
    },
    [onClickDismiss, t],
  );

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <InactivityComponent>
          <SettingsProvider settings={settings}>
            <SnackbarProvider
              ref={notistackRef}
              maxSnack={1}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              action={snackbarDefaultAction}
            >
              <Router />
            </SnackbarProvider>
          </SettingsProvider>
        </InactivityComponent>
      </QueryClientProvider>
    </>
  );
};
