import { ConsentSessionClient, SambiLoginClient } from '@sekoia/shared/requests/nswagClients/NLLClient';

export class NLLContextClient {
  private _consentSessionClient: ConsentSessionClient | undefined;
  public get consentSessionClient(): ConsentSessionClient {
    if (!this._consentSessionClient) {
      this._consentSessionClient = new ConsentSessionClient();
    }
    return this._consentSessionClient;
  }

  private _sambiLoginClient: SambiLoginClient | undefined;
  public get sambiLoginClient(): SambiLoginClient {
    if (!this._sambiLoginClient) {
      this._sambiLoginClient = new SambiLoginClient();
    }
    return this._sambiLoginClient;
  }
}
