import {
  MaritalStatusClient,
  ResidentClient,
  LocationClient,
  InternalContactClient,
  EmployeeClient,
  EmployeeLocationClient,
  EmployeeDelegationsClient,
  ContactClient,
  BasicInformationClient,
  UserClient,
  DelegationClient,
  TagsClient,
  AccessControlTagClient,
  ResidentAccessClient,
  ProfessionClient,
  ResidentDataTypeAccessControlTagsClient,
  ProfessionConfigurationClient,
} from '@sekoia/shared/requests/nswagClients/AtlasClient';

export class AtlasContextClient {
  private _employeeDelegationsClient: EmployeeDelegationsClient | undefined;
  public get EmployeeDelegationsClient(): EmployeeDelegationsClient {
    if (!this._employeeDelegationsClient) {
      this._employeeDelegationsClient = new EmployeeDelegationsClient();
    }
    return this._employeeDelegationsClient;
  }

  private _residentClient: ResidentClient | undefined;
  public get residentClient(): ResidentClient {
    if (!this._residentClient) {
      this._residentClient = new ResidentClient();
    }
    return this._residentClient;
  }

  private _maritalStatusClient: MaritalStatusClient | undefined;
  public get maritalStatusClient(): MaritalStatusClient {
    if (!this._maritalStatusClient) {
      this._maritalStatusClient = new MaritalStatusClient();
    }
    return this._maritalStatusClient;
  }

  private _locationClient: LocationClient | undefined;
  public get locationClient(): LocationClient {
    if (!this._locationClient) {
      this._locationClient = new LocationClient();
    }
    return this._locationClient;
  }

  private _internalContactClient: InternalContactClient | undefined;
  public get internalContactClient(): InternalContactClient {
    if (!this._internalContactClient) {
      this._internalContactClient = new InternalContactClient();
    }
    return this._internalContactClient;
  }

  private _employeeClient: EmployeeClient | undefined;
  public get employeeClient(): EmployeeClient {
    if (!this._employeeClient) {
      this._employeeClient = new EmployeeClient();
    }
    return this._employeeClient;
  }

  private _employeeLocationClient!: EmployeeLocationClient;
  public get employeeLocationClient(): EmployeeLocationClient {
    if (!this._employeeLocationClient) {
      this._employeeLocationClient = new EmployeeLocationClient();
    }
    return this._employeeLocationClient;
  }

  private _contactClient: ContactClient | undefined;
  public get contactClient(): ContactClient {
    if (!this._contactClient) {
      this._contactClient = new ContactClient();
    }
    return this._contactClient;
  }

  private _basicInformationClient: BasicInformationClient | undefined;
  public get basicInformationClient(): BasicInformationClient {
    if (!this._basicInformationClient) {
      this._basicInformationClient = new BasicInformationClient();
    }
    return this._basicInformationClient;
  }

  private _userClient: UserClient | undefined;
  public get userClient(): UserClient {
    if (!this._userClient) {
      this._userClient = new UserClient();
    }
    return this._userClient;
  }

  private _delegationClient: DelegationClient | undefined;
  public get delegationClient(): DelegationClient {
    if (!this._delegationClient) {
      this._delegationClient = new DelegationClient();
    }

    return this._delegationClient;
  }

  private _tagsClient: TagsClient | undefined;
  public get tagsClient(): TagsClient {
    if (!this._tagsClient) {
      this._tagsClient = new TagsClient();
    }

    return this._tagsClient;
  }

  private _accessControlTagClient: AccessControlTagClient | undefined;
  public get accessControlTagClient(): AccessControlTagClient {
    if (!this._accessControlTagClient) {
      this._accessControlTagClient = new AccessControlTagClient();
    }
    return this._accessControlTagClient;
  }

  private _residentAccessClient: ResidentAccessClient | undefined;
  public get residentAccessClient(): ResidentAccessClient {
    if (!this._residentAccessClient) {
      this._residentAccessClient = new ResidentAccessClient();
    }
    return this._residentAccessClient;
  }

  private _professionClient: ProfessionClient | undefined;
  public get professionClient(): ProfessionClient {
    if (!this._professionClient) {
      this._professionClient = new ProfessionClient();
    }
    return this._professionClient;
  }

  private _residentDataTypeAccessControlTagsClient: ResidentDataTypeAccessControlTagsClient | undefined;
  public get residentDataTypeAccessControlTagsClient(): ResidentDataTypeAccessControlTagsClient {
    if (!this._residentDataTypeAccessControlTagsClient) {
      this._residentDataTypeAccessControlTagsClient = new ResidentDataTypeAccessControlTagsClient();
    }
    return this._residentDataTypeAccessControlTagsClient;
  }

  private _professionConfigurationClient: ProfessionConfigurationClient | undefined;
  public get professionConfigurationClient(): ProfessionConfigurationClient {
    if (!this._professionConfigurationClient) {
      this._professionConfigurationClient = new ProfessionConfigurationClient();
    }
    return this._professionConfigurationClient;
  }
}
