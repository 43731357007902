import React, { ReactElement } from 'react';
import { getProfileById } from 'requests/profileClient';
import CacheKeys from 'constants/CacheKeys';
import LoadingScreen from 'components/LoadingScreen';
import { Authentication } from 'utils/Authentication/Authentication';
import { ai } from '@sekoia/shared/utils/telemetryService';
import ErrorPage from 'components/ErrorPage';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useQueryWithRetry } from '@sekoia/shared/hooks/helpers/useQueryWithRetry';
import { useProfileContext } from 'context/Profile';

type Props = {
  children: ReactElement;
};

const FetchAndEnsureCoreData = ({ children }: Props) => {
  const { t } = useTranslation(['global']);
  const currentProfile = useProfileContext();
  const {
    data: profile,
    status: profileStatus,
    error: profileError,
  } = useQueryWithRetry({
    queryKey: [CacheKeys.PROFILE, currentProfile.globalId],
    queryFn: () => getProfileById(),
    throwOnError: (error) => {
      if ((error as AxiosError).isAxiosError) {
        if ((error as AxiosError).response?.status === 401) {
          Authentication.Instance.login();
          return false;
        }
      }
      ai.trackException({
        exception: error,
      });
      return false;
    },
  });

  if (profileStatus === 'error') {
    ai.trackException({
      exception: new Error(`Failed to fetch profile: ${(profileError as Error).message}`),
    });
  }

  if (profileStatus === 'success') {
    const updateCurrentProfile = async () => {
      await Authentication.Instance.setCurrentProfile(profile);
    };
    updateCurrentProfile();
  }

  if (profileStatus === 'error') {
    return (
      <ErrorPage
        title={t('fullscreenErrorTitle')}
        description={'fullscreenErrorDescription'}
        buttonText={t('tryAgain')}
        shouldReload={true}
      />
    );
  }

  if (profileStatus === 'pending') return <LoadingScreen />;

  return <>{children}</>;
};

export default FetchAndEnsureCoreData;
