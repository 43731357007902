import CurrentProfileManager from '@sekoia/shared/utils/currentProfileManager';
import daLocale from 'date-fns/locale/da';
import enLocale from 'date-fns/locale/en-GB';
import svLocale from 'date-fns/locale/sv';
import nlLocale from 'date-fns/locale/nl';

export const getLocaleFromDateFns = () => {
  const cultureInfoCode = CurrentProfileManager.Instance.getCurrentProfile().cultureInfoCode;

  switch (cultureInfoCode) {
    case 'da-DK':
      return daLocale;
    case 'en-GB':
      return enLocale;
    case 'sv-SE':
      return svLocale;
    case 'nl-NL':
      return nlLocale;
    default:
      return enLocale;
  }
};
