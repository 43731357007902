import { RestClientsProvider } from '@sekoia/shared';
import React, { Suspense } from 'react';
import { Paths } from 'constants/Paths';
import LoadingScreen from 'components/LoadingScreen';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import BaseError404View from 'views/base/BaseError404View';
import WalkMe from 'components/WalkMe';
import ScrollReset from 'components/ScrollReset';
import FetchAndEnsureCoreData from 'FetchAndEnsureCoreData';
import { TokenProvider } from 'context/Token';
import { TogglesProvider } from 'context/Toggles/TogglesProvider';
import { RestClientsProvider as RestClientsProviderOld } from 'context/RestClients/RestClientsProvider';
import { ProtectedRoute } from 'routes/ProtectedRoute';
import { ExternalRoutes } from './ExternalRoutes';
import { AppProvider } from 'AppProvider';
import { ProfileProvider } from 'context/Profile';

const RouterProtected = React.lazy(() => import('./RouterProtected'));

export const Router: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route exact path={Paths.HOME.href} render={() => <Redirect to={Paths.APP.href} />} />
        <Route exact path={Paths.NOTFOUND.href} component={BaseError404View} />
        <ProtectedRoute path={Paths.APP.href}>
          <TokenProvider>
            <RestClientsProvider>
              <RestClientsProviderOld>
                <ProfileProvider>
                  <FetchAndEnsureCoreData>
                    <AppProvider>
                      <TogglesProvider>
                        <RouterProtected />
                        <WalkMe />
                        <ScrollReset />
                      </TogglesProvider>
                    </AppProvider>
                  </FetchAndEnsureCoreData>
                </ProfileProvider>
              </RestClientsProviderOld>
            </RestClientsProvider>
          </TokenProvider>
        </ProtectedRoute>
        <ExternalRoutes />
      </Switch>
    </Suspense>
  );
};
