import api from '../api';
import notifUtils from '../utils/notifications';
import { getIcon } from '../icons';
import { nanoid } from 'nanoid';
import onFailError from '../utils/onFailError';
import { readLocalFile } from '../utils/upload';
import icons from '../icons-svg';
import getMess from '../translations';
import { normalizeResource } from '../utils/common';

const label = 'upload';

async function handler(apiOptions, actions) {
  const { navigateToDir, updateNotifications, getResource, getNotifications } = actions;

  const getMessage = getMess.bind(null, apiOptions.locale);

  const notificationId = label;
  const notificationChildId = nanoid();
  const prevResourceId = getResource().id;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onStart = ({ name, size }) => {
    const notifications = getNotifications();
    const notification = notifUtils.getNotification(notifications, notificationId);
    const childElement = {
      elementType: 'NotificationProgressItem',
      elementProps: {
        title: name,
        progress: 0,
        icon: getIcon({ name }),
      },
    };

    const newChildren = notifUtils.addChild(
      (notification && notification.children) || [],
      notificationChildId,
      childElement,
    );
    const newNotification = {
      title:
        newChildren.length > 1
          ? getMessage('uploadingItems', { quantity: newChildren.length })
          : getMessage('uploadingItem'),
      children: newChildren,
    };

    const newNotifications = notification
      ? notifUtils.updateNotification(notifications, notificationId, newNotification)
      : notifUtils.addNotification(notifications, notificationId, newNotification);

    updateNotifications(newNotifications);
  };

  const onProgress = (progress) => {
    const notifications = getNotifications();
    const notification = notifUtils.getNotification(notifications, notificationId);
    const child = notifUtils.getChild(notification.children, notificationChildId);
    const newChild = {
      ...child,
      element: {
        ...child.element,
        elementProps: {
          ...child.element.elementProps,
          progress,
        },
      },
    };
    const newChildren = notifUtils.updateChild(notification.children, notificationChildId, newChild);
    const newNotifications = notifUtils.updateNotification(notifications, notificationId, { children: newChildren });
    updateNotifications(newNotifications);
  };

  const checkFileNameExists = (file) => {
    return actions.getResourceChildren().filter((f) => f.name === file.name);
  };

  const uploadFile = async (file, fileId) => {
    onStart({ name: file.name, size: file.file.size });
    let response;
    if (fileId) {
      response = await api.overWriteFile({ apiOptions, parentId: resource.id, file, fileId, onProgress });
    } else {
      response = await api.uploadFile({ apiOptions, parentId: resource.id, file, onProgress });
    }

    const newResource = normalizeResource(response.data, apiOptions.permissionsUiActions);
    const notifications = getNotifications();
    const notification = notifUtils.getNotification(notifications, notificationId);
    const notificationChildrenCount = notification.children.length;
    let newNotifications;
    if (notificationChildrenCount > 1) {
      newNotifications = notifUtils.updateNotification(notifications, notificationId, {
        children: notifUtils.removeChild(notification.children, notificationChildId),
      });
    } else {
      newNotifications = notifUtils.removeNotification(notifications, notificationId);
    }
    updateNotifications(newNotifications);
    if (prevResourceId === resource.id) {
      navigateToDir(resource.id, newResource.id, false);
    }
  };

  const resource = getResource();
  try {
    const file = await readLocalFile(true);

    const onAcceptOverwrite = async (fileId) => {
      apiOptions.closeReplaceFileDialogHandler();
      try {
        await uploadFile(file, fileId);
      } catch (err) {
        const statusCode = err.response && err.response.status;

        onFailError({
          appInsights: apiOptions.appInsights,
          getNotifications,
          label: getMessage(label),
          notificationId,
          updateNotifications,
          statusCode,
        });
      }
    };

    const filesWithSameName = checkFileNameExists(file);
    if (filesWithSameName.length > 0) {
      apiOptions.openReplaceFileDialogHandler(async () => await onAcceptOverwrite(filesWithSameName[0].id));
    } else {
      await uploadFile(file);
    }
  } catch (err) {
    const statusCode = err.response && err.response.status;
    onFailError({
      appInsights: apiOptions.appInsights,
      getNotifications,
      label: getMessage(label),
      notificationId,
      updateNotifications,
      statusCode,
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (apiOptions, actions) => {
  const localeLabel = getMess(apiOptions.locale, label);
  const { getResource } = actions;
  return {
    id: label,
    icon: { svg: icons.fileUpload },
    label: localeLabel,
    shouldBeAvailable: () => {
      const resource = getResource();
      if (!resource || !resource.capabilities) {
        return false;
      }
      return resource.capabilities.canAddChildren;
    },
    availableInContexts: ['files-view', 'new-button'],
    handler: () => handler(apiOptions, actions),
  };
};
