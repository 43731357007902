import axios, { AxiosInstance } from 'axios';
import { Authentication } from 'utils/Authentication/Authentication';
import { getSessionId } from 'utils/sessionId';
import { v4 as uuidv4 } from 'uuid';

export class AuthenticationClient {
  private static _axios?: AxiosInstance;

  static get Instance() {
    if (!AuthenticationClient._axios) {
      throw Error(`No Axios instance created, did you forget to create an Authentication Client?`);
    }

    return AuthenticationClient._axios;
  }

  constructor(customerId?: string) {
    let authenticationUrl = process.env.LOGIN_SERVICE_URL;
    //TEMP CODE TO TEST NEW AUTH
    const newAuth = localStorage.getItem('newauth');
    if (newAuth && newAuth === 'true' && process.env.LOGIN_SERVICE_URL_V2) {
      authenticationUrl = process.env.LOGIN_SERVICE_URL_V2;
    }
    // Return old client, if config matches new, otherwise delete it and create a new
    if (
      AuthenticationClient._axios &&
      AuthenticationClient._axios.defaults.baseURL === authenticationUrl &&
      AuthenticationClient._axios.defaults.headers.common['sekoia.customer_id'] === customerId
    ) {
      return AuthenticationClient._axios;
    } else {
      delete AuthenticationClient._axios;
    }

    const sessionId = getSessionId();
    AuthenticationClient._axios = axios.create({
      baseURL: authenticationUrl,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    AuthenticationClient._axios.defaults.headers.common['sekoia.session_id'] = sessionId;
    AuthenticationClient._axios.defaults.headers.common['sekoia.customer_id'] = customerId ?? '';

    AuthenticationClient._axios.interceptors.request.use(async (req) => {
      if (!req.headers) return req;
      if (!req.headers['no-auth']) {
        // Refetch token on every request to ensure silent refresh works
        const token = await Authentication.Instance.getToken();
        req.headers['Authorization'] = `Bearer ${token}`;
      }

      // Set new correlation_id per request
      req.headers['sekoia.correlation_id'] = uuidv4();

      return req;
    });

    AuthenticationClient._axios.interceptors.response.use(async (res) => {
      if (res.status === 401) {
        Authentication.Instance.login();
      }

      return res;
    });

    return AuthenticationClient._axios;
  }

  static createInstance(customerId?: string) {
    new AuthenticationClient(customerId);
  }
}
