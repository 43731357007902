import React, { useContext, useEffect } from 'react';
import { useAssignUserToInvitatedGroup } from 'hooks/requests/invitations/useAssignUserToInvitatedGroup';
import { useParams } from 'react-router';
import { Card, CardHeader, Grid } from '@mui/material';
import { ParamsKey } from 'routes/Params';
import { useInvitationStyles } from 'views/external/invitations/styles';
import { useTranslation } from 'react-i18next';
import { usePrevious } from '@sekoia/shared/hooks/common/usePrevious';
import { Paths } from 'constants/Paths';
import { useHistory } from 'react-router-dom';
import LoadingScreen from 'components/LoadingScreen';
import { InvitationContext } from 'views/external/invitations/InvitationActivityGroupContext';
import { useAssignUserAsARelative } from 'hooks/requests/invitations/useAssignUserAsARelative';
import { InvitationType } from 'hooks/requests/invitations/invitations.types';

type LoginToGroupProps = {
  invitationType: InvitationType;
};

export const LoginToInvitation = ({ invitationType }: LoginToGroupProps) => {
  const { t } = useTranslation(['global']);
  const { key } = useParams<ParamsKey>();
  const classes = useInvitationStyles();
  const history = useHistory();
  const invitation = useContext(InvitationContext);

  if (!invitation) {
    throw Error(`No invitation validated!`);
  }

  const {
    mutateAsync: assignUserToGroup,
    isError: assignUserToGrupError,
    isSuccess: assignUserToGroupSuccess,
  } = useAssignUserToInvitatedGroup(invitation.compassUrl, key);
  const {
    mutateAsync: assignRelativeInvitation,
    isError: relativeInvitationError,
    isSuccess: relativeInvitationIsSuccess,
  } = useAssignUserAsARelative(invitation.compassUrl, key);
  const prevIsSuccessGroup = usePrevious(assignUserToGroupSuccess);
  const prevIsSuccessRelative = usePrevious(relativeInvitationIsSuccess);

  useEffect(() => {
    if (invitationType === 'activityGroup') {
      assignUserToGroup();
    } else if (invitationType === 'relative') {
      assignRelativeInvitation();
    }

    // Only trigger assignUserToGroup on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((assignUserToGroupSuccess && !prevIsSuccessGroup) || (relativeInvitationIsSuccess && !prevIsSuccessRelative)) {
      history.replace(Paths.ACTIVITY_GROUPS.href);
    }
  }, [history, assignUserToGroupSuccess, prevIsSuccessGroup, relativeInvitationIsSuccess, prevIsSuccessRelative]);

  if (assignUserToGrupError || relativeInvitationError) {
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={t('global:error')} subheader={t('global:errorSaving')} />
          </Card>
        </Grid>
      </Grid>
    );
  }

  return <LoadingScreen />;
};
