import {
  InventoryItemClient,
  ProductCategoryClient,
  ProductClient,
  ProductTypeClient,
  ServiceTypeClient,
  InventoryItemSourceClient,
} from '@sekoia/shared/requests/nswagClients/InventoryClient';

export class InventoryContextClient {
  private _productCategoryClient: ProductCategoryClient | undefined;
  public get productCategoryClient(): ProductCategoryClient {
    if (!this._productCategoryClient) {
      this._productCategoryClient = new ProductCategoryClient();
    }
    return this._productCategoryClient;
  }

  private _productTypeClient: ProductTypeClient | undefined;
  public get productTypeClient(): ProductTypeClient {
    if (!this._productTypeClient) {
      this._productTypeClient = new ProductTypeClient();
    }
    return this._productTypeClient;
  }

  private _productClient: ProductClient | undefined;
  public get productClient(): ProductClient {
    if (!this._productClient) {
      this._productClient = new ProductClient();
    }
    return this._productClient;
  }

  private _inventoryItemClient: InventoryItemClient | undefined;
  public get inventoryItemClient(): InventoryItemClient {
    if (!this._inventoryItemClient) {
      this._inventoryItemClient = new InventoryItemClient();
    }
    return this._inventoryItemClient;
  }

  private _serviceTypeClient: ServiceTypeClient | undefined;
  public get serviceTypeClient(): ServiceTypeClient {
    if (!this._serviceTypeClient) {
      this._serviceTypeClient = new ServiceTypeClient();
    }
    return this._serviceTypeClient;
  }

  private _inventoryItemSourceClient: InventoryItemSourceClient | undefined;
  public get inventoryItemSourceClient(): InventoryItemSourceClient {
    if (!this._inventoryItemSourceClient) {
      this._inventoryItemSourceClient = new InventoryItemSourceClient();
    }
    return this._inventoryItemSourceClient;
  }
}
