import React, { ReactNode } from 'react';
import useTogglesEnabledForAnyAffiliatedOrganizations from 'hooks/useTogglesEnabledForAnyAffiliatedOrganizations';
import { Redirect } from 'react-router';
import { Paths } from 'constants/Paths';
import { isArray } from 'lodash';
import { useProfileContext } from 'context/Profile';

type Props = {
  children: ReactNode;
  toggleName: string | string[];
};

const ToggleGuardWithRedirect = ({ toggleName, children }: Props) => {
  const toggles = useTogglesEnabledForAnyAffiliatedOrganizations();
  const currentProfile = useProfileContext();
  const organisationIds = currentProfile.organizationIds;

  const isToggledOn = toggles.some((toggle) => {
    let hasToggle = false;
    if (isArray(toggleName)) {
      hasToggle = toggleName.some((t) => t === toggle.name);
    } else hasToggle = toggle.name === toggleName;
    const check = hasToggle && toggle.IsEnabledForAnyAffiliatedOrganization(organisationIds);
    return check;
  });

  if (isToggledOn) {
    return <>{children}</>;
  }

  return <Redirect to={Paths.APP.href} />;
};

export default ToggleGuardWithRedirect;
