import {
  IncidentsClient,
  IncidentsTemplateClient,
  ActionClient,
  AnalysisClient,
} from '@sekoia/shared/requests/nswagClients/IncidentsClient';

export class IncidentsContextClient {
  private _incidentsClient: IncidentsClient | undefined;
  public get incidentsClient(): IncidentsClient {
    if (!this._incidentsClient) {
      this._incidentsClient = new IncidentsClient();
    }
    return this._incidentsClient;
  }

  private _incidentsTemplateClient: IncidentsTemplateClient | undefined;
  public get incidentsTemplateClient(): IncidentsTemplateClient {
    if (!this._incidentsTemplateClient) {
      this._incidentsTemplateClient = new IncidentsTemplateClient();
    }
    return this._incidentsTemplateClient;
  }

  private _actionClient: ActionClient | undefined;
  public get actionClient(): ActionClient {
    if (!this._actionClient) {
      this._actionClient = new ActionClient();
    }
    return this._actionClient;
  }

  private _analysisClient: AnalysisClient | undefined;
  public get analysisClient(): AnalysisClient {
    if (!this._analysisClient) {
      this._analysisClient = new AnalysisClient();
    }
    return this._analysisClient;
  }
}
