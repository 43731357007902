import { DashboardClient, WidgetClient } from '@sekoia/shared/requests/nswagClients/DashboardsClient';

export class DashboardsContextClient {
  private _dashboardsClient: DashboardClient | undefined;
  public get dashboardsClient(): DashboardClient {
    if (!this._dashboardsClient) {
      this._dashboardsClient = new DashboardClient();
    }
    return this._dashboardsClient;
  }

  private _widgetClient: WidgetClient | undefined;
  public get widgetClient(): WidgetClient {
    if (!this._widgetClient) {
      this._widgetClient = new WidgetClient();
    }
    return this._widgetClient;
  }
}
