import api from '../api';
import sanitizeFilename from 'sanitize-filename';
import onFailError from '../utils/onFailError';
import icons from '../icons-svg';
import getMess from '../translations';

const label = 'rename';

function removeExtension(filename) {
  const lastDotPosition = filename.lastIndexOf('.');
  if (lastDotPosition === -1) return filename;
  else return filename.substr(0, lastDotPosition);
}

function handler(apiOptions, actions) {
  const {
    showDialog,
    hideDialog,
    navigateToDir,
    updateNotifications,
    getSelectedResources,
    getResource,
    getNotifications,
  } = actions;

  const getMessage = getMess.bind(null, apiOptions.locale);
  const localeLabel = getMessage(label);
  const rawDialogElement = {
    elementType: 'SetNameDialog',
    elementProps: {
      initialValue: removeExtension(getSelectedResources()[0].name),
      onHide: hideDialog,
      onSubmit: async (name) => {
        const selectedResources = getSelectedResources();
        try {
          const resourceChildren = await api.getChildrenForId(apiOptions, { id: selectedResources[0].parentId });
          const alreadyExists = resourceChildren.some((o) => removeExtension(o.name) === name);
          if (alreadyExists) {
            return getMessage('fileExist', { name });
          } else {
            hideDialog();
            const result = await api.renameResource(apiOptions, selectedResources[0].id, name, selectedResources[0]);
            const resource = getResource();
            navigateToDir(resource.id, result.data ? result.data.id : resource.id, false);
          }
          return null;
        } catch (err) {
          hideDialog();
          onFailError({
            ai: apiOptions.api,
            getNotifications,
            label: localeLabel,
            notificationId: label,
            updateNotifications,
          });
          return null;
        }
      },
      onValidate: async (name) => {
        if (!name) {
          return getMessage('emptyName');
        } else if (name.length >= 255) {
          return getMessage('tooLongFolderName');
        } else if (name.trim() !== sanitizeFilename(name.trim())) {
          return getMessage('folderNameNotAllowedCharacters');
        }
        return null;
      },
      inputLabelText: getMessage('newName'),
      headerText: getMessage('rename'),
      submitButtonText: localeLabel,
      cancelButtonText: getMessage('cancel'),
    },
  };
  showDialog(rawDialogElement);
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (apiOptions, actions) => {
  const localeLabel = getMess(apiOptions.locale, label);
  const { getSelectedResources } = actions;
  return {
    id: label,
    icon: { svg: icons.rename },
    label: localeLabel,
    shouldBeAvailable: () => {
      const selectedResources = getSelectedResources();
      return selectedResources.length === 1 && selectedResources.every((r) => r.capabilities.canRename);
    },
    availableInContexts: ['row', 'toolbar'],
    handler: () => handler(apiOptions, actions),
  };
};
