import { NotificationsClient, SubscriptionsClient } from '@sekoia/shared/requests/nswagClients/NotificationsClient';

export class NotificationsContextClient {
  private _notificationsClient: NotificationsClient | undefined;
  public get notificationsClient(): NotificationsClient {
    if (!this._notificationsClient) {
      this._notificationsClient = new NotificationsClient();
    }
    return this._notificationsClient;
  }

  private _subscriptionsClient: SubscriptionsClient | undefined;
  public get subscriptionsClient(): SubscriptionsClient {
    if (!this._subscriptionsClient) {
      this._subscriptionsClient = new SubscriptionsClient();
    }

    return this._subscriptionsClient;
  }
}
