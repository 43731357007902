import { useContext } from 'react';
import { RestClientsContext } from '../../contexts/RestClients/RestClientsContext';
import { ai } from '@sekoia/shared/utils/telemetryService';

export const useObservationsClient = () => {
  const { observations } = useContext(RestClientsContext);

  if (!observations) {
    ai.trackException(
      `No Observations client found, did you forget to wrap the application with a <RestClientsProvider />?`,
    );
    throw new Error(
      `No Observations client found, did you forget to wrap the application with '<RestClientsProvider />?`,
    );
  }

  return observations;
};
