import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

let v = process.env.VERSION;
if (!v) {
  v = Math.random().toString(36);
}

const options = {
  fallbackLng: {
    'da-DK': ['da'],
    'sv-SE': ['sv'],
    'nl-NL': ['nl'],
    no: ['no-NO'],
    'no-NO': ['no-NO'],
    'no-bok': ['no-NO'],
    'no-nyn': ['no-NO'],
    en: ['en-GB'],
    default: ['en-GB'],
  },
  supportedLngs: ['da-DK', 'da', 'sv-SE', 'sv', 'ss', 'nl-NL', 'nl', 'en-GB', 'en', 'no', 'no-NO', 'no-bok', 'no-nyn'],
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag'],
  },
  debug: false,
  backend: { queryStringParams: { v: v } },
};

if (process.env.NODE_ENV !== 'production') {
  options.debug = true;
  options.fallbackLng.default = ['ss'];
  i18n.use(Backend).use(initReactI18next).init(options);
} else {
  i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(options);
}
