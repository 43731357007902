import React, { ReactNode } from 'react';
import { Redirect } from 'react-router';
import { RoleRequirement } from '@sekoia/shared/domain/User/roles';
import { Paths } from 'constants/Paths';
import { useTokenContext } from 'context/Token';
import { useProfileContext } from 'context/Profile';

type Props = {
  children: ReactNode;
  roleRequirement?: RoleRequirement;
  professionLogoTypeIds?: number[];
};

const RoleOrProfessionGuardWithRedirect = ({ roleRequirement, professionLogoTypeIds, children }: Props) => {
  const { professionLogoTypeId } = useTokenContext();
  const currentProfile = useProfileContext();
  const userRoles = currentProfile.roles;

  if (roleRequirement && userRoles.some((role) => roleRequirement.allowedRoles.some((r) => r.name === role))) {
    return <>{children}</>;
  }

  if (professionLogoTypeIds && professionLogoTypeIds.includes(professionLogoTypeId)) {
    return <>{children}</>;
  }

  return <Redirect to={Paths.APP.href} />;
};

export default RoleOrProfessionGuardWithRedirect;
