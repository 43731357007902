import React, { useContext, useMemo, Suspense } from 'react';
import { RoleRequirement } from '@sekoia/shared/domain/User/roles';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import RoleOrProfessionGuardWithRedirect from 'routes/RoleOrProfessionGuardWithRedirect';
import ToggleGuardWithRedirect from 'routes/ToggleGuardWithRedirect';
import { AuthContext } from 'context/Auth/AuthProvider';
import LoadingScreen from 'components/LoadingScreen';

type ProtectedRouteProps = RouteProps & {
  toggleName?: string | string[];
  roles?: ReturnType<typeof RoleRequirement.create>;
  professionLogoTypeIds?: number[];
};

export const ProtectedRoute: React.FC<React.PropsWithChildren<ProtectedRouteProps>> = (props) => {
  const { children, toggleName, roles, path, exact, professionLogoTypeIds } = props;
  const { isAuthenticated, login } = useContext(AuthContext);

  const routeComp = useMemo(
    () => (
      <Route path={path} exact={exact}>
        {children}
      </Route>
    ),
    [children, exact, path],
  );
  const result = useMemo(() => {
    let result = routeComp;
    if (roles || professionLogoTypeIds) {
      result = (
        <RoleOrProfessionGuardWithRedirect roleRequirement={roles} professionLogoTypeIds={professionLogoTypeIds}>
          {result}
        </RoleOrProfessionGuardWithRedirect>
      );
    }
    if (toggleName) {
      result = <ToggleGuardWithRedirect toggleName={toggleName}>{result}</ToggleGuardWithRedirect>;
    }

    return result;
  }, [professionLogoTypeIds, roles, routeComp, toggleName]);

  if (!isAuthenticated) {
    login();
    return null;
  }

  return <Suspense fallback={<LoadingScreen />}>{result}</Suspense>;
};
