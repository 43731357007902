import { useContext } from 'react';
import { RestClientsContext } from '../../contexts/RestClients/RestClientsContext';
import { ai } from '@sekoia/shared/utils/telemetryService';

export const useHealthMeasurementsClient = () => {
  const { healthMeasurements } = useContext(RestClientsContext);

  if (!healthMeasurements) {
    ai.trackException(
      `No healthMeasurements client found, did you forget to wrap the application with a <RestClientsProvider />?`,
    );
    throw new Error(
      `No healthMeasurements client found, did you forget to wrap the application with '<RestClientsProvider />?`,
    );
  }

  return healthMeasurements;
};
