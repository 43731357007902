import React, { useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import Typography from '@mui/material/Typography';
import { sortArrayOfObjects } from '@sekoia/shared/utils/sortHelpers';
import { Stack } from '@mui/material';
import ProfilePictureAvatar from '../Avatar/ProfilePictureAvatar';

const LISTBOX_PADDING = 8;

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
    height: 56,
  };

  const profile = dataSet[1] as IdAndName;

  return (
    <Stack component="li" style={inlineStyle} {...dataSet[0]} direction={'row'} spacing={1}>
      <ProfilePictureAvatar globalId={profile.id} customerId={profile.customerId} />
      <Typography noWrap>{profile.name}</Typography>
      {profile.ssn && (
        <Typography variant={'body2'} color={'textSecondary'}>
          {profile.ssn}
        </Typography>
      )}
    </Stack>
  );
};

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: number) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export type IdAndName = {
  name: string;
  customerId: string;
  id: string;
  ssn?: string;
};

type ProfileSelectorProps = {
  label: string;
  noOptionsText: string;
  placeholder?: string;
  handleOnProfileChange?: (profile: IdAndName | undefined) => void;
  handleOnProfilesChange?: (profile: IdAndName[]) => void;
  value?: string | string[] | null;
  multiple?: boolean;
  required?: boolean;
  profiles: IdAndName[];
};

const ProfileSelector: React.FC<React.PropsWithChildren<ProfileSelectorProps>> = (props) => {
  const {
    label,
    noOptionsText,
    placeholder,
    handleOnProfileChange,
    handleOnProfilesChange,
    value,
    multiple = false,
    required = false,
    profiles,
  } = props;

  const profilesSorted = useMemo(() => {
    return sortArrayOfObjects(profiles, 'name');
  }, [profiles]);

  const selectedProfiles = useMemo(() => {
    if (value === undefined) return undefined;
    if (!multiple) {
      const profile = profiles.find((e) => e.id === value);
      return profile ?? null;
    }
    if (multiple && Array.isArray(value)) {
      return profiles.filter((e) => value.includes(e.id));
    }
    return multiple ? [] : null;
  }, [profiles, multiple, value]);

  const onProfileChange = (_: unknown, profilesIdAndName: IdAndName | IdAndName[] | null) => {
    if (!profilesIdAndName && handleOnProfileChange) return handleOnProfileChange(undefined);
    if (!profilesIdAndName && handleOnProfilesChange) return handleOnProfilesChange([]);
    if (Array.isArray(profilesIdAndName)) {
      handleOnProfilesChange && handleOnProfilesChange(profilesIdAndName ?? []);
    } else {
      if (profilesIdAndName) {
        handleOnProfileChange && handleOnProfileChange(profilesIdAndName);
      }
    }
  };

  const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
      const { children, ...other } = props;
      const itemData: React.ReactChild[] = [];
      (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      });

      const itemCount = itemData.length;
      const itemSize = 56;
      const gridRef = useResetCache(itemCount);

      useEffect(() => {
        if (value) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const index = itemData.findIndex((i) => (i as any)[1].id === value);
          gridRef.current?.scrollToItem(index, 'start');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [gridRef]);

      const getChildSize = () => {
        return itemSize;
      };

      const getHeight = () => {
        if (itemCount > 8) {
          return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
      };

      return (
        <div ref={ref}>
          <OuterElementContext.Provider value={other}>
            <VariableSizeList
              itemData={itemData}
              height={getHeight() + 2 * LISTBOX_PADDING}
              width="100%"
              ref={gridRef}
              outerElementType={OuterElementType}
              innerElementType="ul"
              itemSize={() => getChildSize()}
              overscanCount={5}
              itemCount={itemCount}
            >
              {renderRow}
            </VariableSizeList>
          </OuterElementContext.Provider>
        </div>
      );
    },
  );

  return (
    <Autocomplete
      multiple={multiple}
      id="profile-selector"
      autoSelect
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={profilesSorted}
      onChange={onProfileChange}
      getOptionLabel={(e) => {
        return `${e.name}${e.ssn ? ` (${e.ssn})` : ''}`;
      }}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} label={label} required={required} />}
      renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
      noOptionsText={noOptionsText}
      value={selectedProfiles}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
    />
  );
};

export default ProfileSelector;
ProfileSelector.displayName = 'ProfileSelector';
