import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { TFunction } from 'i18next';

type OverwriteDialogProps = {
  openDialog: boolean;
  overwriteFileCallback: () => Promise<void>;
  handleShowOverwriteConfirmDialog: (state: boolean) => void;
  translations: TFunction;
};

function OverwriteDialog(props: OverwriteDialogProps) {
  const t = props.translations;
  const [showOverwriteConfirmDialog, setShowOverwriteConfirmDialog] = React.useState(false);

  React.useEffect(() => {
    setShowOverwriteConfirmDialog(props.openDialog);
  }, [props.openDialog]);

  return (
    <Dialog
      open={showOverwriteConfirmDialog}
      onClose={() => props.handleShowOverwriteConfirmDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('files:replace.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t('files:replace.description')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleShowOverwriteConfirmDialog(false)} color="primary">
          {t('global:cancel')}
        </Button>
        <Button
          color="primary"
          autoFocus
          onClick={() =>
            props.overwriteFileCallback ? props.overwriteFileCallback() : props.handleShowOverwriteConfirmDialog(false)
          }
        >
          {t('files:replace.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(OverwriteDialog);
