import {
  RegistrationClient,
  RegistrationFollowUpClient,
  FluidTypeClient,
  TargetValueClient,
  ReportClient,
} from '@sekoia/shared/requests/nswagClients/FluidsClient';

export class FluidsContextClient {
  private _registrationClient: RegistrationClient | undefined;
  private _registrationFollowUpClient: RegistrationFollowUpClient | undefined;
  private _fluidTypeClient: FluidTypeClient | undefined;
  private _targetValueClient: TargetValueClient | undefined;
  private _reportClient: ReportClient | undefined;

  public get registrationClient(): RegistrationClient {
    if (!this._registrationClient) {
      this._registrationClient = new RegistrationClient();
    }
    return this._registrationClient;
  }

  public get registrationFollowUpClient() {
    if (!this._registrationFollowUpClient) {
      this._registrationFollowUpClient = new RegistrationFollowUpClient();
    }

    return this._registrationFollowUpClient;
  }

  public get fluidTypeClient(): FluidTypeClient {
    if (!this._fluidTypeClient) {
      this._fluidTypeClient = new FluidTypeClient();
    }

    return this._fluidTypeClient;
  }

  public get targetValueClient(): TargetValueClient {
    if (!this._targetValueClient) {
      this._targetValueClient = new TargetValueClient();
    }

    return this._targetValueClient;
  }

  public get reportClient(): ReportClient {
    if (!this._reportClient) {
      this._reportClient = new ReportClient();
    }

    return this._reportClient;
  }
}
