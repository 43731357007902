import {
  ObservationsClient,
  ResidentClient,
  HealthMeasurementClient,
  PlannedAdministrationsClient,
  PlannedDosedAdministrationsClient,
  PlannedNotDispensedAdministrationClient,
  NexusTokenClient,
  PnAdministrationsClient,
  PlansVumClient,
  PnPrescriptionsClient,
  ConfigurationClient,
  HandlingsanvisningerClient,
} from '@sekoia/shared/requests/nswagClients/NexusClient';

export class NexusContextClient {
  private _observationsClient: ObservationsClient | undefined;
  public get observationsClient(): ObservationsClient {
    if (!this._observationsClient) {
      this._observationsClient = new ObservationsClient();
    }

    return this._observationsClient;
  }

  private _residentClient: ResidentClient | undefined;
  public get residentClient(): ResidentClient {
    if (!this._residentClient) {
      this._residentClient = new ResidentClient();
    }

    return this._residentClient;
  }

  private _healthMeasurementClient: HealthMeasurementClient | undefined;
  public get healthMeasurementClient(): HealthMeasurementClient {
    if (!this._healthMeasurementClient) {
      this._healthMeasurementClient = new HealthMeasurementClient();
    }

    return this._healthMeasurementClient;
  }

  private _plannedAdministrationsClient: PlannedAdministrationsClient | undefined;
  public get plannedAdministrationsClient(): PlannedAdministrationsClient {
    if (!this._plannedAdministrationsClient) {
      this._plannedAdministrationsClient = new PlannedAdministrationsClient();
    }

    return this._plannedAdministrationsClient;
  }

  private _plannedDosedAdministrationsClient: PlannedDosedAdministrationsClient | undefined;
  public get plannedDosedAdministrationsClient(): PlannedDosedAdministrationsClient {
    if (!this._plannedDosedAdministrationsClient) {
      this._plannedDosedAdministrationsClient = new PlannedDosedAdministrationsClient();
    }

    return this._plannedDosedAdministrationsClient;
  }

  private _plannedNotDispensedAdministrationClient: PlannedNotDispensedAdministrationClient | undefined;
  public get plannedNotDispensedAdministrationClient(): PlannedNotDispensedAdministrationClient {
    if (!this._plannedNotDispensedAdministrationClient) {
      this._plannedNotDispensedAdministrationClient = new PlannedNotDispensedAdministrationClient();
    }

    return this._plannedNotDispensedAdministrationClient;
  }

  private _nexusTokenClient: NexusTokenClient | undefined;
  public get nexusTokenClient(): NexusTokenClient {
    if (!this._nexusTokenClient) {
      this._nexusTokenClient = new NexusTokenClient();
    }

    return this._nexusTokenClient;
  }

  private _pnAdministrationsClient: PnAdministrationsClient | undefined;
  public get pnAdministrationsClient(): PnAdministrationsClient {
    if (!this._pnAdministrationsClient) {
      this._pnAdministrationsClient = new PnAdministrationsClient();
    }

    return this._pnAdministrationsClient;
  }

  private _plansVumClient: PlansVumClient | undefined;
  public get plansVumClient(): PlansVumClient {
    if (!this._plansVumClient) {
      this._plansVumClient = new PlansVumClient();
    }

    return this._plansVumClient;
  }

  private _pnPrescriptionsClient: PnPrescriptionsClient | undefined;
  public get pnPrescriptionsClient(): PnPrescriptionsClient {
    if (!this._pnPrescriptionsClient) {
      this._pnPrescriptionsClient = new PnPrescriptionsClient();
    }

    return this._pnPrescriptionsClient;
  }

  private _configurationClient: ConfigurationClient | undefined;
  public get configurationClient(): ConfigurationClient {
    if (!this._configurationClient) {
      this._configurationClient = new ConfigurationClient();
    }

    return this._configurationClient;
  }

  private _handlingsanvisningerClient: HandlingsanvisningerClient | undefined;
  public get handlingsanvisningerClient(): HandlingsanvisningerClient {
    if (!this._handlingsanvisningerClient) {
      this._handlingsanvisningerClient = new HandlingsanvisningerClient();
    }

    return this._handlingsanvisningerClient;
  }
}
