import { useMutation } from '@tanstack/react-query';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { useCallback } from 'react';
import axios from 'axios';

function useSetContactPassword() {
  const setContactPassword = useCallback(async (key: string, password: string) => {
    return axios
      .post(`${process.env.ATLAS_SERVICE_URL}/api/user/changepassword/anonymous`, {
        resetKey: key,
        newPassword: password,
      })
      .then((response) => response.data);
  }, []);

  return useMutation({
    mutationFn: async (values: UseSetContactPasswordMutationRequest) => {
      await setContactPassword(values.key, values.password);
    },

    onError: (error) => {
      ai.trackException({
        exception: new Error(`Failed to set contact password: ${(error as Error).message}`),
      });
    }
  });
}

export default useSetContactPassword;

export interface UseSetContactPasswordMutationRequest {
  key: string;
  password: string;
}
