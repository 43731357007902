import { ProfileResponse } from '@sekoia/shared/requests/nswagClients/AtlasClient';

const emptyProfile = (): CurrentProfile => {
  return {
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    id: '',
    legacyId: 0,
    customerUrl: '',
    customerId: '',
    roles: [],
    cultureInfoCode: '',
  };
};

export default class CurrentProfileManager {
  public static Instance = new CurrentProfileManager();
  private currentProfile: CurrentProfile = emptyProfile();

  public getCurrentProfile(): CurrentProfile {
    return this.currentProfile;
  }

  public setCustomerInfo(customerInfo: CustomerInfo) {
    this.currentProfile.customerUrl = customerInfo.compassUrl;
    this.currentProfile.customerId = customerInfo.customerId;
    this.currentProfile.cultureInfoCode = customerInfo.cultureInfoCode;
  }

  public setIdAndLegacyId(id: string, legacyId: number) {
    this.currentProfile.id = id;
    this.currentProfile.legacyId = legacyId;
  }

  public async setCurrentProfile(profile: ProfileResponse, userRoles: string[]): Promise<void> {
    this.currentProfile.firstName = profile.firstName ?? '';
    this.currentProfile.lastName = profile.lastName ?? '';
    this.currentProfile.fullName = `${profile.firstName} ${profile.lastName}`;
    this.currentProfile.email = profile.email ?? '';
    this.currentProfile.roles = userRoles;
  }
}

export type CurrentProfile = {
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  id: string;
  legacyId: number;
  customerUrl: string;
  customerId: string;
  roles: string[];
  cultureInfoCode: string;
};

export type CustomerInfo = {
  customerId: string;
  compassUrl: string;
  cultureInfoCode: string;
};
