import { UserClient } from '@sekoia/shared/requests/nswagClients/AtlasClient';
import { ProfileResponse } from '@sekoia/shared/requests/nswagClients/AtlasClient';

export const getProfileById = async (): Promise<ProfileResponse> => {
  const userClient = new UserClient();

  const userProfile = await userClient.getProfileInformationAboutMe();

  return userProfile;
};
