import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, LinearProgress, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { getThemeSpacingFromUnit } from '../utils/styleHelper';

type LoadingScreenProps = {
  contentLoader?: boolean;
  width?: number | `${number}%`;
  style?: React.CSSProperties;
  backgroundColor?: string;
  isMobile?: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: getThemeSpacingFromUnit(3),
  },
  contentLoader: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.54)',
    zIndex: 2,
  },
}));

function LoadingScreen({ contentLoader = false, width = 400, style, backgroundColor, isMobile }: LoadingScreenProps) {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div
      className={clsx(classes.root, contentLoader && classes.contentLoader)}
      style={{ ...style, backgroundColor: backgroundColor ?? theme.palette.background.default }}
    >
      <Box width={isMobile ? 250 : width}>
        <LinearProgress />
      </Box>
    </div>
  );
}

export default LoadingScreen;
