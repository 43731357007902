import { TogglesContext } from 'context/Toggles/TogglesContext';
import { shallowEqual } from 'fast-equals';
import { useFetchAffiliatedOrganizations } from 'hooks/requests/employees/useFetchAffiliatedOrganizations';
import { useContext, useEffect, useState } from 'react';
import { Toggle } from 'requests/toggleServiceClient';

/**
 * Checks if Toggle(s) are enabled for any affiliated organizations or enabled for customer
 */
function useTogglesEnabledForAnyAffiliatedOrganizations(): Toggle[];
function useTogglesEnabledForAnyAffiliatedOrganizations(...togglesToCheck: string[]): boolean[];

function useTogglesEnabledForAnyAffiliatedOrganizations(): boolean[] | Toggle[] {
  // eslint-disable-next-line prefer-rest-params
  const togglesToCheck = arguments.length > 0 ? Array.from(arguments) : undefined;
  const toggles = useContext(TogglesContext);
  const { data: myOrganizations, error: myOrganizationsError } = useFetchAffiliatedOrganizations();
  const [organizationIds, setOrganizationIds] = useState<string[]>();
  const [togglesResult, setTogglesResult] = useState<Toggle[] | boolean[]>(() =>
    togglesToCheck ? togglesToCheck.map(() => false) : toggles,
  );

  useEffect(() => {
    if (myOrganizations) {
      setOrganizationIds(myOrganizations.map((o) => o.id));
    }
  }, [myOrganizations]);

  useEffect(() => {
    if (togglesToCheck && Array.isArray(togglesToCheck)) {
      if (organizationIds === undefined) return;
      const nextResult: boolean[] = [];
      togglesToCheck.forEach((toggle) => {
        nextResult.push(
          toggles.some((t) => t.name === toggle && t.IsEnabledForAnyAffiliatedOrganization(organizationIds)),
        );
      });
      if (!shallowEqual(nextResult, togglesResult)) {
        setTogglesResult(nextResult);
      }
    } else {
      if (!shallowEqual(togglesResult, toggles)) {
        setTogglesResult(toggles);
      }
    }
  }, [organizationIds, toggles, togglesResult, togglesToCheck]);

  if (myOrganizationsError) {
    throw Error(`Failed to fetch my organizations while checking toggles`);
  }
  return togglesResult;
}

export default useTogglesEnabledForAnyAffiliatedOrganizations;
