import {
  CustomerTogglesClient,
  FeatureEnabledForOrganisation,
  CustomerToggleResponse,
} from '@sekoia/shared/requests/nswagClients/TogglesClient';
import currentProfileManager from '@sekoia/shared/utils/currentProfileManager';

const client = new CustomerTogglesClient();

const toggleServiceClient = async (): Promise<Toggle[]> => {
  const organisationSpecificToggles = await client.getCustomerToggles(
    currentProfileManager.Instance.getCurrentProfile().customerId,
  );

  const result = organisationSpecificToggles.map((toggle) => new Toggle(toggle));

  return result;
};

export default toggleServiceClient;

export class Toggle {
  name: string;
  isEnabledForCustomer: boolean;
  featureEnabledForOrganisations: FeatureEnabledForOrganisation[];

  constructor(toggle: CustomerToggleResponse) {
    this.name = toggle.name;
    this.isEnabledForCustomer = toggle.isEnabledForCustomer;
    this.featureEnabledForOrganisations = toggle.featureEnabledForOrganisations.map(
      (x) =>
        new FeatureEnabledForOrganisation({
          isEnabled: x.isEnabled,
          organisationId: x.organisationId.toLowerCase(),
        }),
    );
  }

  IsEnabledForAnyAffiliatedOrganization(organisationIds: string[]): boolean {
    if (organisationIds.length === 0 || this.featureEnabledForOrganisations.length === 0)
      return this.isEnabledForCustomer;

    const organisationIdsLowerCase = organisationIds.map((id) => id.toLowerCase());
    const toggleOrgIds = this.featureEnabledForOrganisations.map((f) => f.organisationId);

    const enabledForAnyAffilliatedOrganisation = this.featureEnabledForOrganisations.filter(
      (t) => organisationIdsLowerCase.includes(t.organisationId) && t.isEnabled,
    );

    const noToggleForOrganisationButEnabledForCustomer =
      this.isEnabledForCustomer && organisationIdsLowerCase.some((orgId) => !toggleOrgIds.includes(orgId));

    return enabledForAnyAffilliatedOrganisation.length > 0 || noToggleForOrganisationButEnabledForCustomer;
  }
}
