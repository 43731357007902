import {
  AssignmentClient,
  TaskImagesClient,
  StandardTasksClient,
  AssociationsClient,
  TaskReportMetricClient,
  TaskInstanceClient,
  TaskAssignmentClient,
} from '@sekoia/shared/requests/nswagClients/TasksClient';

export class TasksContextClient {
  private _assignmentClient: AssignmentClient | undefined;
  public get assignmentClient(): AssignmentClient {
    if (!this._assignmentClient) {
      this._assignmentClient = new AssignmentClient();
    }

    return this._assignmentClient;
  }

  private _taskImagesClient: TaskImagesClient | undefined;
  public get taskImagesClient(): TaskImagesClient {
    if (!this._taskImagesClient) {
      this._taskImagesClient = new TaskImagesClient();
    }

    return this._taskImagesClient;
  }

  private _standardTasksClient: StandardTasksClient | undefined;
  public get standardTasksClient(): StandardTasksClient {
    if (!this._standardTasksClient) {
      this._standardTasksClient = new StandardTasksClient();
    }

    return this._standardTasksClient;
  }

  private _associationsClient: AssociationsClient | undefined;
  public get associationsClient(): AssociationsClient {
    if (!this._associationsClient) {
      this._associationsClient = new AssociationsClient();
    }

    return this._associationsClient;
  }

  private _taskReportMetricClient: TaskReportMetricClient | undefined;
  public get taskReportMetricClient(): TaskReportMetricClient {
    if (!this._taskReportMetricClient) {
      this._taskReportMetricClient = new TaskReportMetricClient();
    }

    return this._taskReportMetricClient;
  }

  private _taskInstanceClient: TaskInstanceClient | undefined;
  public get taskInstanceClient(): TaskInstanceClient {
    if (!this._taskInstanceClient) {
      this._taskInstanceClient = new TaskInstanceClient();
    }

    return this._taskInstanceClient;
  }

  private _taskAssignmentClient: TaskAssignmentClient | undefined;
  public get taskAssignmentClient(): TaskAssignmentClient {
    if (!this._taskAssignmentClient) {
      this._taskAssignmentClient = new TaskAssignmentClient();
    }

    return this._taskAssignmentClient;
  }
}
