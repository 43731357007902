import { RecordsClient, FormsClient } from '@sekoia/shared/requests/nswagClients/RecordsClient';

export class RecordsContextClient {
  private _recordsClient: RecordsClient | undefined;
  public get recordsClient(): RecordsClient {
    if (!this._recordsClient) {
      this._recordsClient = new RecordsClient();
    }
    return this._recordsClient;
  }

  private _formsClient: FormsClient | undefined;
  public get formsClient(): FormsClient {
    if (!this._formsClient) {
      this._formsClient = new FormsClient();
    }
    return this._formsClient;
  }
}
