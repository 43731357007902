import React, { ReactNode } from 'react';
import ErrorPage from 'components/ErrorPage';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { useErrorBoundary } from 'react-use-error-boundary';

type BaseErrorViewProps = {
  title: string;
  description: string;
  children: ReactNode;
  buttonText: string;
};

const BaseErrorView: React.FC<React.PropsWithChildren<BaseErrorViewProps>> = (props) => {
  const { title, description, children, buttonText } = props;
  const [error] = useErrorBoundary((error, errorInfo) =>
    ai.trackException({
      exception: new Error(`Error: ${error}, componentStack: ${errorInfo.componentStack}`),
    }),
  );

  if (error) {
    return <ErrorPage buttonText={buttonText} shouldReload={true} title={title} description={description} />;
  }

  return <>{children}</>;
};

export default BaseErrorView;
