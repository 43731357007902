import { useCallback } from 'react';
import { CompassClient } from 'requests/compassClient';
import { useMutation } from '@tanstack/react-query';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { CreateUserRequest } from './invitations.types';

export const useCreateUser = (invitationType: string, relation?: string) => {
  const createUser = useCallback(
    async (request: CreateUserRequest) => {
      const client = CompassClient.Instance;

      let response;
      if (invitationType === 'activityGroup') {
        response = await client.post(`/api/profiles/external/create`, request);
      } else if (invitationType === 'relative') {
        request.relation = relation;
        response = await client.post(`api/profiles/relatives/create`, request);
      }

      return response?.data;
    },
    [invitationType, relation],
  );

  return useMutation({
    mutationFn: createUser,
    onError: (e) => {
      ai.trackException({
        exception: e,
      });
    },
  });
};
