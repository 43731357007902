import { PlansClient, PlanLogClient, PlansHeadingClient } from '@sekoia/shared/requests/nswagClients/PlansClient';

export class PlansContextClient {
  private _plansClient: PlansClient | undefined;
  public get plansClient(): PlansClient {
    if (!this._plansClient) {
      this._plansClient = new PlansClient();
    }
    return this._plansClient;
  }

  private _planLogClient: PlanLogClient | undefined;
  public get planLogClient(): PlanLogClient {
    if (!this._planLogClient) {
      this._planLogClient = new PlanLogClient();
    }

    return this._planLogClient;
  }

  private _plansHeadingClient: PlansHeadingClient | undefined;
  public get PlansHeadingClient(): PlansHeadingClient {
    if (!this._plansHeadingClient) {
      this._plansHeadingClient = new PlansHeadingClient();
    }

    return this._plansHeadingClient;
  }
}
