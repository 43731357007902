import { useCallback, useEffect } from 'react';
import { AuthenticationClient } from 'requests/authenticationClient';
import { useQuery } from '@tanstack/react-query';
import { ai } from '@sekoia/shared/utils/telemetryService';
import { Invitation } from './invitations.types';
import { AxiosError } from 'axios';

export const useFetchRelativeInvitation = (key: string, enabled: boolean) => {
  useEffect(() => {
    AuthenticationClient.createInstance();
  }, []);

  const fetchInvitation = useCallback(async () => {
    const authClient = AuthenticationClient.Instance;
    const resp = await authClient.get<Invitation>(`/relativeInvitations/${key}`);
    return resp.data;
  }, [key]);

  return useQuery<Invitation, AxiosError>({
    queryKey: [`/relativeInvitations/${key}`],
    queryFn: () => fetchInvitation(),
    retry: false,
    enabled: enabled,
    throwOnError: (e) => {
      ai.trackException({
        exception: e,
      });
      return false;
    },
  });
};
