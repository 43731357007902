import makeStyles from '@mui/styles/makeStyles';

export const useInvitationStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 800,
    padding: theme.spacing(2),
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
}));
